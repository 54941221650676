import { useFormik } from 'formik';
import React, { useState } from 'react';
import { FormField, FormWrapper } from '../../common/Form';
import LoadingDialog from '../../common/LoadingDialog';
import * as Yup from 'yup';
import { FETCH_STATES, useFetch } from '../../../hooks/useFetch';
import {
  getUserDistrictInvitation,
  submitUserDistrictInvitation
} from '../api';
import { Link, navigate } from 'gatsby';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import Button from '../../common/Button';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid';

type AcceptDistrictInvitationProps = {
  code?: string;
};

type ActivationDistrictInvitationForm = {
  code: string;
};

type IForm = ActivationDistrictInvitationForm;

const CODE_PATTERN_UUID4 =
  /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/;

const AcceptDistrictInvitationSchema = Yup.object().shape({
  code: Yup.string()
    .required('Code required')
    .matches(CODE_PATTERN_UUID4, 'Bad format')
});

const AcceptDistrictInvitation = (props: AcceptDistrictInvitationProps) => {
  const [code, setCode] = useState(props.code);
  const { isFetching, error, data, refetch } = useQuery<{
    state: 'SUCCESS' | 'ERROR';
    invitation?: any;
  }>(
    ['eventApplications', { code }],
    a => {
      return getUserDistrictInvitation(a?.queryKey?.[1]?.code || ('' as any));
    },
    {
      enabled: !!code
    }
  );

  const {
    apply,
    error: submitError,
    state
  } = useFetch(submitUserDistrictInvitation);

  const handleSubmit = async (values: any) => {
    setCode(values.code);
    const res = await apply(values);
    if (res.state === 'SUCCESS') {
      toast.success('Code activated successfully');
      await refetch();
      navigate(
        '/members/accept-district-invitation/' + res?.data?.invitation?.code
      );
    } else {
      toast.error(submitError);
    }
  };

  const formik = useFormik<IForm>({
    validationSchema: AcceptDistrictInvitationSchema,
    initialValues: {
      code: ''
    },
    onSubmit: handleSubmit
  });

  if (isFetching) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <LoadingDialog />
      </div>
    );
  }

  if (!props.code) {
    return (
      <div className="flex flex-col items-center justify-center gap-6 p-6 text-center">
        <h1 className="text-primary">Welcome, here!</h1>
        <span className="block max-w-md text-lg text-primary-dark">
          Try to confirm using code:
        </span>
        <div className="max-w-md text-lg">
          <FormWrapper
            formik={formik}
            isLoading={state === FETCH_STATES.PROCESSING}
            submitBtnText="Confirm"
            btnsAlign="center"
          >
            <FormField<IForm>
              name="code"
              type="text"
              className="w-72"
              placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
            />
          </FormWrapper>
        </div>
      </div>
    );
  }

  if (!data?.invitation) {
    return (
      <div className="flex flex-col items-center justify-center gap-6 p-6 text-center">
        <h1 className="text-primary">Ooops, sorry!</h1>
        <XCircleIcon className="w-16 text-red-500" />
        <p className="max-w-md text-lg">Your code is invalid!</p>
        <p className="max-w-md text-lg">Try to confirm using code:</p>
        <div>
          <Button
            text="Try confirm using code "
            onClick={() => navigate('/members/accept-district-invitation')}
            color="main"
            size="md"
            isLoading={false}
          />
        </div>
      </div>
    );
  }

  // not activated
  if (data?.invitation.status === 'SENT') {
    const invitation = data.invitation;
    return (
      <div className="flex flex-col items-center justify-center gap-6 p-6 text-center">
        <h1 className="text-primary">
          Welcome, {invitation.user.firstName + ' ' + invitation.user.lastName}!
        </h1>
        <p className="max-w-md text-lg">
          You have been invited to {invitation.district.name} district!
        </p>
        <p className="max-w-md text-lg">
          Please, click on the botton below to confirm:
        </p>
        <div>
          <Button
            text="Confim"
            onClick={() => handleSubmit({ code: props.code })}
            color="main"
            size="md"
            isLoading={state === FETCH_STATES.PROCESSING}
          />
        </div>
        <p className="mt-5 max-w-md text-sm">Or try to confirm another code</p>
        <div>
          <Button
            text="Try"
            onClick={() => navigate('/members/accept-district-invitation')}
            color="main"
            size="sm"
            disabled={state === FETCH_STATES.PROCESSING}
          />
        </div>
      </div>
    );
  }

  // already activated
  return (
    <div className="flex flex-col items-center justify-center gap-6 p-6 text-center">
      <h1 className="text-primary">
        Welcome,{' '}
        {data?.invitation.user.firstName + ' ' + data?.invitation.user.lastName}
        !
      </h1>
      <CheckCircleIcon className="w-16 text-green-500" />
      <p className="max-w-md text-lg">
        Your affiliation has been successfully activated
      </p>
      <p className="max-w-md text-md text-blue-400">
        <Link to="/members/profile">Go to profile &rarr; </Link>
      </p>
    </div>
  );
};

export default AcceptDistrictInvitation;
