import React from 'react';

interface ProductPanelTitleProps {
  title: string;
}
export const ProductPanelTitle = (props: ProductPanelTitleProps) => (
  <p className="text-sm text-gray-500 uppercase font-bold sans-serif mb-1">
    {props.title}
  </p>
);
