import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface UnableToEditNoticeProps {
  text: string;
}
const UnableToEditNotice = (props: UnableToEditNoticeProps) => {
  return (
    <div className="border-red-500 border p-4 rounded-lg text-red-500 flex items-center justify-center text-sm">
      <FontAwesomeIcon icon={faExclamation} />
      <p className="text-gray-600 sans-serif ml-4">{props.text}</p>
    </div>
  );
};

export default UnableToEditNotice;
