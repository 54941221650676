import { Disclosure } from '@headlessui/react';
import { EventSectionTicketAssignment } from '../conference-days/ConferenceDaysAssignTickets';
import { UserOptionType } from '../members/AssignTickets';
import classnames from 'classnames';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChevronUpIcon } from '@heroicons/react/solid';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { formatAsCurrency } from '../../util';
import { ProductPanelTitle } from '../members/ProductPanel';
import UserSelect from '../members/UserSelect';
import { EventTicketAssignment } from './EventAssignTickets';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import UnableToEditNotice from '../members/UnableToEditNotice';

export interface EventSectionTicketPanelProps {
  open: boolean;
  title: string;
  ticketTitle: string;
  ticketPrice: number;
  actualAmount: number;
  dates: string;
  users: UserOptionType[];
  blockApplicantAllocationMessage?: string;
  assignments: EventSectionTicketAssignment | EventTicketAssignment;
  onAssignmentSelect: (a: { userId: string; position: number }) => void;
  onClearAssignment: (a: { userId: string; position: number }) => void;
}

function allAssignmentsComplete(assignments: UserOptionType[], count: number) {
  return assignments?.filter(Boolean).length === count;
}

const EventTicketPanel = (props: EventSectionTicketPanelProps) => {
  const buttonClasses = classnames({
    'flex justify-between w-full px-4 py-2 font-medium text-left text-blue-100 bg-primary rounded-t-lg hover:bg-primary-dark focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75 sans-serif': true,
    'rounded-b-lg': !props.open
  });
  const chevronClasses = classnames({
    'w-5 h-5 text-blue-100': true,
    'transform rotate-180': props.open
  });

  return (
    <>
      <Disclosure.Button className={buttonClasses}>
        <span className="sans-serif text-lg">
          <FontAwesomeIcon
            icon={
              allAssignmentsComplete(
                props.assignments.assignedUsers,
                props.actualAmount
              )
                ? faCheckCircle
                : faCircle
            }
            className="text-blue-100 mr-2"
          />
          {props.title}
        </span>
        <ChevronUpIcon className={chevronClasses} />
      </Disclosure.Button>
      <Disclosure.Panel className="p-6 text-gray-700 sans-serif border-gray-300 border-b border-l border-r rounded-b-lg">
        {props.blockApplicantAllocationMessage && (
          <div className="w-1/2 m-auto mt-2 mb-6">
            <UnableToEditNotice text={props.blockApplicantAllocationMessage} />
          </div>
        )}
        <div>
          <div className="flex justify-between">
            <div className="w-3/4 flex">
              <div className="mr-10">
                <ProductPanelTitle title="Price" />
                <p>{formatAsCurrency((props.ticketPrice || 0) / 100)}</p>
              </div>
              <div className="mr-10">
                <ProductPanelTitle title="Number of Tickets" />
                <p>{props.actualAmount}</p>
              </div>
              <div className="mr-10">
                <ProductPanelTitle title="Total Cost" />
                <p>
                  {formatAsCurrency(
                    ((props.ticketPrice || 0) / 100) * props.actualAmount
                  )}
                </p>
              </div>
              <div className="mr-10">
                <ProductPanelTitle title="Date" />
                <p>{props.dates}</p>
              </div>
            </div>

            <div className="w-1/4 ml-4">
              {Array(props.actualAmount)
                .fill(0)
                .map((_n, i: number, arr) => {
                  const userMatch = props.assignments.assignedUsers[i] || null;
                  return (
                    <div className="mb-3" key={i}>
                      <ProductPanelTitle
                        title={
                          arr.length < 2 ? `Assignment` : `Assignment ${i + 1}`
                        }
                      />
                      <UserSelect
                        isDisabled={!!props.blockApplicantAllocationMessage}
                        // @ts-ignore
                        value={userMatch || null}
                        options={
                          props.users.filter(
                            u =>
                              !props.assignments.assignedUsers.some(
                                au => au && au.value === u.value
                              )
                          ) || []
                        }
                        onChange={(value: any) =>
                          props.onAssignmentSelect({
                            userId: value.value,
                            position: i
                          })
                        }
                      />
                      {userMatch && (
                        <div className="flex justify-end">
                          <button
                            disabled={!!props.blockApplicantAllocationMessage}
                            className="mt-1 text-sm sans-serif text-red-600 text-right"
                            onClick={() =>
                              props.onClearAssignment({
                                userId: userMatch.value,
                                position: i
                              })
                            }
                          >
                            Clear selection
                          </button>
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Disclosure.Panel>
    </>
  );
};

export default EventTicketPanel;
