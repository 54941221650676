import React from 'react';
import classnames from 'classnames';
import {
  faCheckCircle,
  faExclamationTriangle,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export enum EventPaymentStatus {
  PENDING = 'PENDING',
  AWAITING_PO = 'AWAITING_PO',
  PO_SUBMITTED = 'PO_SUBMITTED',
  PAID = 'PAID',
  AWAITING_INVOICE = 'AWAITING_INVOICE',
  INVOICED = 'INVOICED',
  CANCELLED = 'CANCELLED',
  COMPLIMENTARY = 'COMPLIMENTARY'
}

function getStatusIcon(packageStatus: EventPaymentStatus) {
  let icon;
  switch (packageStatus) {
    case EventPaymentStatus.PO_SUBMITTED:
      icon = faExclamationTriangle;
      break;
    case EventPaymentStatus.PENDING:
    case EventPaymentStatus.AWAITING_PO:
      icon = faExclamationTriangle;
      break;
    case EventPaymentStatus.AWAITING_INVOICE:
      icon = faExclamationTriangle;
      break;
    case EventPaymentStatus.INVOICED:
    case EventPaymentStatus.PAID:
    case EventPaymentStatus.COMPLIMENTARY:
      icon = faCheckCircle;
      break;
  }
  return icon;
}

function getStatusText(packageStatus: EventPaymentStatus) {
  let text;
  switch (packageStatus) {
    case EventPaymentStatus.PAID:
    case EventPaymentStatus.COMPLIMENTARY:
      text = 'Paid';
      break;
    case EventPaymentStatus.AWAITING_PO:
      text = 'Awaiting Puchase Order';
      break;
    case EventPaymentStatus.PENDING:
      text = 'Payment Incomplete';
      break;
    case EventPaymentStatus.PO_SUBMITTED:
      text = 'Purchase order submitted';
      break;
    case EventPaymentStatus.AWAITING_INVOICE:
      text = 'Invoice pending';
      break;
    case EventPaymentStatus.INVOICED:
      text = 'Invoice sent';
      break;
  }
  return text;
}

const ApplicationPaymentStatus = (props: { status: EventPaymentStatus }) => {
  const statusClasses = classnames({
    'mt-2 sans-serif': true,
    'text-yellow-500': [
      EventPaymentStatus.PO_SUBMITTED,
      EventPaymentStatus.PENDING,
      EventPaymentStatus.AWAITING_PO,
      EventPaymentStatus.AWAITING_INVOICE,
      EventPaymentStatus.INVOICED
    ].includes(props.status),
    'text-green-600': [
      EventPaymentStatus.INVOICED,
      EventPaymentStatus.PAID,
      EventPaymentStatus.COMPLIMENTARY
    ].includes(props.status)
  });
  const statusDescriptionClasses = classnames({
    'ml-2 sans-serif': true,
    'text-yellow-600': [
      EventPaymentStatus.PO_SUBMITTED,
      EventPaymentStatus.PENDING,
      EventPaymentStatus.AWAITING_PO,
      EventPaymentStatus.AWAITING_INVOICE,
      EventPaymentStatus.INVOICED
    ].includes(props.status),
    'text-green-600': [
      EventPaymentStatus.INVOICED,
      EventPaymentStatus.PAID,
      EventPaymentStatus.COMPLIMENTARY
    ].includes(props.status)
  });
  return (
    <p className={statusClasses}>
      <FontAwesomeIcon icon={getStatusIcon(props.status) as IconDefinition} />
      <span className={statusDescriptionClasses}>
        {getStatusText(props.status)}
      </span>
    </p>
  );
};

export default ApplicationPaymentStatus;
