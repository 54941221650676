import React from 'react';
import classnames from 'classnames';

interface TabButtonProps {
  text: string;
  onClick: any;
  isActive: boolean;
}

const TabButton = (props: TabButtonProps) => {
  const buttonClasses = classnames({
    'sans-serif text-lg py-4 px-8 hover:underline hover:text-primary hover:font-bold hover:bg-gray-200 active:outline-none focus:outline-none': true,
    'text-primary bg-gray-200 font-bold underline': props.isActive,
    'text-gray-600 bg-gray-100': !props.isActive
  });
  return (
    <button className={buttonClasses} onClick={props.onClick}>
      {props.text}
    </button>
  );
};

export default TabButton;
