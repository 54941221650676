import React, { useContext, useState } from 'react';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import { navigate } from '@reach/router';
import classnames from 'classnames';
import { useQuery } from 'react-query';
import Select from 'react-select';
import { AuthContext } from '../../context/AuthContext';
import { PackageContext } from '../../context/PackageContext';
import { fillRange, getManageServiceRoute } from '../../util';
import Card from '../common/Card';
import LoadingDialog from '../common/LoadingDialog';
import SEO from '../Seo';
import { formatAsCurrency } from './../../util';
import { calculateOrganizationPackageTotals } from './CostUtil';
import { OrganizationPackage, PackageTicket } from './OrganizationPackageTypes';
import PackageCostTotals, {
  PackageRequestTotals,
  getTotalEventCost
} from './PackageCostTotals';
import PackageSaveControls from './PackageSaveControls';
import { getPackageRequestById, getPackageRequestTotals } from './api';
import SelectionInstructions from './SelectionInstructions';
import {
  PackageEvent,
  PackageRequest,
  PackageRequestEvent
} from './NewApiTypes.generated';
import {
  ManageServicesEnum,
  getManageServicesNextStep,
  getManageServicesPreviousStep
} from './Packages';
interface EventOptionProps {
  title: string;
  description: string;
  price: number;
  open: boolean;
  complete: boolean;
  eventOptions: { value: number; label: string }[];
  onParticipantsSelect: (v: { value: number | string; label: string }) => any;
  onParticipantsClear: () => any;
  existingSelection: PackageRequestEvent;
  principalsConferenceTickets: any;
  ticketId: string;
  isSubmitted?: boolean;
}

const EventOption = (props: EventOptionProps) => {
  const buttonClasses = classnames({
    'flex justify-between w-full px-4 py-2 font-medium text-left text-blue-100 bg-primary rounded-t-lg hover:bg-primary-dark focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75 sans-serif': true,
    'rounded-b-lg': !props.open
  });
  const chevronClasses = classnames({
    'w-5 h-5 text-blue-100': true,
    'transform rotate-180': props.open
  });

  const isOverriden = !!props.existingSelection?.overrideDate;
  const isCreatedByAdmin =
    props.existingSelection && !props.existingSelection.createdByInitiator;

  const originalDefaultValue = props.eventOptions.find(
    option => option.value === props.existingSelection?.requestedParticipants
  );

  const overridenDefaultValue = props.eventOptions.find(
    option => option.value === props.existingSelection?.overrideAmount
  );

  const defaultValue = isOverriden
    ? overridenDefaultValue
    : originalDefaultValue;

  return (
    <>
      <Disclosure.Button className={buttonClasses}>
        <span className="sans-serif text-lg">
          <FontAwesomeIcon
            icon={(defaultValue?.value || 0) > 0 ? faCheckCircle : faCircle}
            className="text-blue-100 mr-2"
          />
          {props.title}
        </span>
        <ChevronUpIcon className={chevronClasses} />
      </Disclosure.Button>
      <Disclosure.Panel className="p-6 text-gray-700 sans-serif border-gray-300 border-b border-l border-r rounded-b-lg">
        <div className="sans-serif text-lg flex justify-between">
          <div className="flex">
            <div className="mr-10">
              <p className="text-sm text-gray-500 uppercase font-bold sans-serif">
                Price Per Participant
              </p>
              <p className="text-xl text-gray-800 sans-serif">
                {formatAsCurrency(props.price)}
              </p>
            </div>
          </div>
          {isOverriden && (
            <div className="ml-auto mr-3 text-yellow-600">{`Requested Participants were overriden by admin, orriginal request - ${originalDefaultValue?.label}`}</div>
          )}
          {isCreatedByAdmin && (
            <div className="ml-auto mr-3 text-yellow-600">{`Created by admin`}</div>
          )}
          <div className="w-64">
            <p className="text-sm text-gray-500 uppercase font-bold sans-serif mb-2">
              Participants
            </p>
            <>
              {props.isSubmitted ? (
                <span>{defaultValue?.label || '0'}</span>
              ) : (
                <>
                  <Select
                    options={props.eventOptions}
                    value={defaultValue || null}
                    onChange={v => props.onParticipantsSelect(v as any)}
                    isDisabled={isCreatedByAdmin || isOverriden}
                  />
                  {!!defaultValue && !(isOverriden || isCreatedByAdmin) && (
                    <button
                      type="button"
                      className="mt-1 ml-1 flex items-center gap-2"
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        props.onParticipantsClear();
                      }}
                    >
                      <span className="text-red-400"> Clear</span>
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="text-red-400"
                      />
                    </button>
                  )}
                </>
              )}
            </>
            {props.existingSelection && (
              <div className="mt-4 text-right">
                <span className="sans-serif">
                  {formatAsCurrency(
                    getTotalEventCost([props.existingSelection])
                  )}
                </span>
              </div>
            )}
          </div>
        </div>
      </Disclosure.Panel>
    </>
  );
};

function makeEventOptions(ticket: PackageTicket) {
  const { MaximumAmount, MinimumAmount, Increment } = ticket;

  const eventOptions = [];

  for (let i = MinimumAmount; i <= MaximumAmount; i += Increment) {
    eventOptions.push({ value: i, label: i.toString() });
  }

  return eventOptions;
}

function getEventTicketTotal(
  packageTicketSelections: PackageTicketSelection[]
) {
  return packageTicketSelections
    .map(t => t.participantsSelected * t.ticketPrice)
    .reduce((acc, cur) => acc + cur, 0);
}

type PackageTicketSelection = {
  packageTicketId: number;
  participantsSelected: number;
  ticketPrice: number;
};

interface EventSelectionProps {
  path: string;
  packageRequestId: string;
}

const EventSelection = (props: EventSelectionProps) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [eventSelections, setEventSelections] = useState<
    Partial<PackageRequestEvent>[]
  >([]);
  const [eventSelectionsForDelete, setEventSelectionsForDelete] = useState<
    Partial<PackageRequestEvent>[]
  >([]);
  const packageContext = useContext(PackageContext);

  const { data, isLoading } = useQuery<{
    packageRequest: PackageRequest;
  }>(
    'packageRequestQuery',
    () => getPackageRequestById(props.packageRequestId),
    {
      onSuccess: data => {
        const preparedEventSelections =
          data.packageRequest?.requestedEvents || [];
        setEventSelections(preparedEventSelections as PackageRequestEvent[]);
        setIsSubmitted(data.packageRequest.status === 'SUBMITTED');
      }
    }
  );

  const { data: totalsData, isLoading: isTotalsLoading } = useQuery<{
    pdfData: any;
    totals: PackageRequestTotals;
  }>(['packageRequestTotalsQuery', eventSelections], () =>
    getPackageRequestTotals(props.packageRequestId, {
      requestedEvents: eventSelections
    })
  );

  return (
    <>
      <SEO title="Event Selection" />
      <Card>
        {isLoading ? <LoadingDialog /> : null}
        {data && data.packageRequest.package && (
          <>
            <h2 className="sans-serif text-primary font-bold text-2xl border-b border-gray-300">
              {data.packageRequest.organization.name} -{' '}
              {data.packageRequest.package.name}
            </h2>
            <h3 className="text-2xl text-gray-600 font-bold mt-4">
              Event Selection
            </h3>
            <div
              className="mt-4"
              dangerouslySetInnerHTML={{
                __html: data.packageRequest.package.eventsDescription || ''
              }}
            />
            <div className="w-1/2 m-auto mt-6">
              <SelectionInstructions isSubmitted={isSubmitted} />
            </div>
            <div className="mt-4">
              {data.packageRequest.package.events.map(
                // @ts-ignore
                (e: PackageEvent) => (
                  <div className="w-full mt-4" key={e.id}>
                    <Disclosure>
                      {({ open }) => {
                        return (
                          <EventOption
                            title={e.event.name}
                            description={e.event.description}
                            isSubmitted={isSubmitted}
                            price={(e.ticketType?.price || 0) / 100}
                            existingSelection={
                              eventSelections.find(
                                s => s.packageEventId === e.id
                              ) as PackageRequestEvent
                            }
                            eventOptions={fillRange(
                              e.minDays,
                              e.maxDays,
                              e.selectionInterval
                            ).map(v => ({
                              value: v,
                              label: v.toString()
                            }))}
                            onParticipantsClear={() => {
                              const newEvent:
                                | Partial<PackageRequestEvent>
                                | undefined = eventSelections.find(
                                ev => ev.packageEventId === e.id
                              );

                              if (newEvent) {
                                packageContext.setHasUnsavedChanges(true);

                                const filteredSelections = eventSelections.filter(
                                  event => event.packageEventId !== e.id
                                );

                                const filteredSelectionsForDelete = eventSelectionsForDelete.filter(
                                  event => event.packageEventId !== e.id
                                );

                                setEventSelections([...filteredSelections]);

                                setEventSelectionsForDelete([
                                  ...filteredSelectionsForDelete,
                                  newEvent
                                ]);
                              }
                            }}
                            onParticipantsSelect={({ value }) => {
                              packageContext.setHasUnsavedChanges(true);

                              const newEvent: Partial<PackageRequestEvent> = eventSelections.find(
                                ev => ev.packageEventId === e.id
                              ) || {
                                createdByInitiator: true,
                                packageEvent: e,
                                packageEventId: e.id
                              };

                              if (newEvent.overrideDate) {
                                newEvent.overrideAmount = parseInt(
                                  String(value)
                                );
                              } else {
                                newEvent.requestedParticipants = parseInt(
                                  String(value)
                                );
                              }

                              const filteredSelections = eventSelections.filter(
                                event => event.packageEventId !== e.id
                              );

                              const filteredSelectionsForDelete = eventSelectionsForDelete.filter(
                                event => event.packageEventId !== e.id
                              );

                              setEventSelections([
                                ...filteredSelections,
                                newEvent
                              ]);

                              setEventSelectionsForDelete([
                                ...filteredSelectionsForDelete
                              ]);
                            }}
                            open={open}
                            complete={false}
                            principalsConferenceTickets={null}
                            ticketId={e.id}
                          />
                        );
                      }}
                    </Disclosure>
                  </div>
                )
              )}

              <div className="flex justify-between">
                <PackageCostTotals
                  isLoading={isTotalsLoading}
                  package={data.packageRequest.package}
                  totals={totalsData?.totals}
                />
                <PackageSaveControls
                  packageRequest={data.packageRequest}
                  segment="EVENTS"
                  segmentData={eventSelections}
                  deleteData={eventSelectionsForDelete}
                  nextRoute={getManageServicesNextStep(
                    ManageServicesEnum.EVENT_SELECTION,
                    data.packageRequest
                  )}
                  previousRoute={getManageServicesPreviousStep(
                    ManageServicesEnum.EVENT_SELECTION,
                    data.packageRequest
                  )}
                />
              </div>
            </div>
          </>
        )}
      </Card>
    </>
  );
};

export default EventSelection;
