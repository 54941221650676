import React from 'react';
interface ManageServicesProps {
  path: string;
  children: React.ReactChildren;
}

const ManageServices = (props: ManageServicesProps) => {
  return <>{props.children}</>;
};

export default ManageServices;
