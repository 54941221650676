import React from 'react';

interface FormLabelProps {
  label: string;
}

const FormLabel = (props: FormLabelProps) => {
  return (
    <label className="sans-serif text-gray-500 text-sm uppercase mb-1">
      {props.label}
    </label>
  );
};
export default FormLabel;
