import React from 'react';
import AcceptDistrictInvitation from './AcceptDistrictInvitation';

type AcceptDistrictInvitationWithCodeProps = {
  path: string;
  code?: string;
};
const AcceptDistrictInvitationWithCode = (
  props: AcceptDistrictInvitationWithCodeProps
) => {
  const { code } = props;
  return <AcceptDistrictInvitation code={code} />;
};

export default AcceptDistrictInvitationWithCode;
