import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface SelectionInstructionProps {
  isSubmitted?: boolean;
}
const SelectionInstructions = (props: SelectionInstructionProps) => {
  return (
    <div className="border-primary border p-4 rounded-lg text-primary flex items-center justify-center">
      <FontAwesomeIcon icon={faInfoCircle} />
      <p className="text-gray-600 sans-serif ml-4">
        {`Please expand the items below to ${
          props.isSubmitted ? 'check' : 'make'
        } your selections`}
      </p>
    </div>
  );
};

export default SelectionInstructions;
