import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import classnames from 'classnames';
import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import Select, { ValueType } from 'react-select';
import { PackageContext } from '../../context/PackageContext';
import Card from '../common/Card';
import LoadingDialog from '../common/LoadingDialog';
import SEO from '../Seo';
import { fillRange, formatAsCurrency, getFirstAndLastDate } from './../../util';
import { getPackageRequestById, getPackageRequestTotals } from './api';
import PackageCostTotals, { PackageRequestTotals } from './PackageCostTotals';
import PackageSaveControls from './PackageSaveControls';
import SelectionInstructions from './SelectionInstructions';
import { QueryReturnState } from './Enum';
import {
  Grade,
  PackageInstitute,
  PackageRequest,
  PackageRequestInstitute,
  PackageRequestInstituteGrade
} from './NewApiTypes.generated';
import {
  ManageServicesEnum,
  getManageServicesNextStep,
  getManageServicesPreviousStep
} from './Packages';

function getParticipantsLeftToAssign(
  participantsCount: number,
  participantsAssigned: number
) {
  return participantsCount - participantsAssigned;
}

function getTotalParticipantsAssigned(selection: PackageRequestInstitute) {
  if (!selection || !selection.requestedParticipantDistribution) return 0;
  return selection.requestedParticipantDistribution
    .map(
      g => (g?.overrideDate ? g?.overrideParticipants : g?.participants) || 0
    )
    .reduce((acc: number, cur: number) => Number(acc) + Number(cur), 0);
}
interface InstituteOptionProps {
  title: string;
  dates: string[];
  price: number;
  open: boolean;
  complete: boolean;
  selectionOptions: { value: number; label: string }[];
  availableGrades: Grade[];
  onParticipantsSelect: (
    v: ValueType<{ value: number; label: number | string }, false>
  ) => any;
  onParticipantsClear: () => any;
  onGradeLevelParticipantSelect: any;
  selection?: PackageRequestInstitute;
  isSubmitted?: boolean;
}

const InstituteOption = (props: InstituteOptionProps) => {
  const isOverriden = !!props.selection?.overrideDate;
  const isCreatedByAdmin =
    props.selection && !props.selection.createdByInitiator;

  const originalDefaultValue = props.selection
    ? props.selectionOptions.find(
        option =>
          option.value.toString() ===
          props.selection?.requestedParticipants.toString()
      )
    : undefined;

  const overridenDefaultValue = props.selection
    ? props.selectionOptions.find(
        option =>
          option.value.toString() ===
          props.selection?.overrideAmount?.toString()
      )
    : undefined;

  const defaultValue = isOverriden
    ? overridenDefaultValue
    : originalDefaultValue;

  const participantsCount = props.selection
    ? (props.selection.overrideDate
        ? props.selection.overrideAmount
        : props.selection.requestedParticipants) || 0
    : 0;

  const participantsAssigned = getTotalParticipantsAssigned(
    props.selection as PackageRequestInstitute
  );

  const buttonClasses = classnames({
    'flex justify-between w-full px-4 py-2 font-medium text-left text-blue-100 rounded-t-lg  focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75 sans-serif': true,
    'rounded-b-lg': !props.open,
    'bg-primary hover:bg-primary-dark': !props.title
      .toLowerCase()
      .includes('virtual'),
    'bg-purple-700 hover:bg-purple-800': props.title
      .toLowerCase()
      .includes('virtual')
  });
  const chevronClasses = classnames({
    'w-5 h-5 text-blue-100': true,
    'transform rotate-180': props.open
  });
  const availableGradesClasses = classnames({
    'flex flex-col border border-gray-300 rounded-md p-4': true,
    'border-yellow-500':
      getParticipantsLeftToAssign(participantsCount, participantsAssigned) >
        0 && !props.isSubmitted,
    'border-red-500':
      getParticipantsLeftToAssign(participantsCount, participantsAssigned) <
        0 && !props.isSubmitted,
    'border-green-600':
      getParticipantsLeftToAssign(participantsCount, participantsAssigned) ===
        0 && !props.isSubmitted
  });
  const placesRemainingToAssignTextClasses = classnames({
    'sans-serif': true,
    'font-bold text-yellow-500':
      getParticipantsLeftToAssign(participantsCount, participantsAssigned) > 0,
    'font-bold text-red-500':
      getParticipantsLeftToAssign(participantsCount, participantsAssigned) < 0,
    'font-bold text-green-600':
      getParticipantsLeftToAssign(participantsCount, participantsAssigned) === 0
  });

  return (
    <>
      <Disclosure.Button className={buttonClasses}>
        <span className="sans-serif text-lg">
          <FontAwesomeIcon
            icon={(defaultValue?.value || 0) > 0 ? faCheckCircle : faCircle}
            className="text-blue-100 mr-2"
          />
          {props.title}
        </span>
        <ChevronUpIcon className={chevronClasses} />
      </Disclosure.Button>
      <Disclosure.Panel className="p-6 text-gray-700 sans-serif border-gray-300 border-b border-l border-r rounded-b-lg">
        <div className="sans-serif text-lg">
          <div className="flex flex-col w-3/4">
            <div className="">
              <p className="text-sm text-gray-500 uppercase font-bold sans-serif">
                Price Per Participant
              </p>
              <p className="text-xl text-gray-800 sans-serif">
                {formatAsCurrency(props.price)}
              </p>
            </div>
            {isOverriden && (
              <div className="ml-auto mr-3 text-yellow-600">{`Requested Participants were overriden by admin, orriginal request - ${originalDefaultValue?.label}`}</div>
            )}
            {isCreatedByAdmin && (
              <div className="ml-auto mr-3 text-yellow-600">{`Created by admin`}</div>
            )}
            <div className="mt-4">
              <p className="text-sm text-gray-500 uppercase font-bold sans-serif">
                Grades
              </p>
              <p className="text-xl text-gray-800 sans-serif">
                {props.availableGrades.map(g => g.name).join(', ')}
              </p>
            </div>
            <div className="mt-4">
              <p className="text-sm text-gray-500 uppercase font-bold sans-serif">
                Dates
              </p>
              <p className="text-gray-800 sans-serif">
                {props.dates[0] === props.dates[1]
                  ? props.dates[0]
                  : `${props.dates[0]} - ${props.dates[1]}`}
              </p>
            </div>
          </div>
          <div>
            <div className="mt-2">
              <div className="w-1/4">
                <p className="text-sm text-gray-500 uppercase font-bold sans-serif mb-2">
                  Participants
                </p>
                <>
                  {props.isSubmitted ? (
                    <span>{defaultValue?.label || '0'}</span>
                  ) : (
                    <>
                      <Select
                        options={props.selectionOptions}
                        value={defaultValue || null}
                        onChange={value => props.onParticipantsSelect(value)}
                        isDisabled={isCreatedByAdmin || isOverriden}
                      />
                      {!!defaultValue && !(isOverriden || isCreatedByAdmin) && (
                        <button
                          type="button"
                          className="mt-1 ml-1 flex items-center gap-2"
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            props.onParticipantsClear();
                          }}
                        >
                          <span className="text-red-400"> Clear</span>
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="text-red-400"
                          />
                        </button>
                      )}
                    </>
                  )}
                </>
              </div>
              {participantsCount > 0 ? (
                <>
                  <div className="mt-6">
                    <p
                      className={`text-sm text-gray-500 uppercase font-bold sans-serif ${
                        props.isSubmitted ? 'mb-2' : ''
                      } `}
                    >
                      {`${!props.isSubmitted ? 'Available ' : ''}Grades`}
                    </p>
                    {!props.isSubmitted && (
                      <p className="sans-serif mb-2">
                        Please select the grade for which your applicants will
                        be participating
                      </p>
                    )}
                  </div>
                  <ul className={availableGradesClasses}>
                    {!props.isSubmitted && (
                      <>
                        <p className={placesRemainingToAssignTextClasses}>
                          Places remaining to assign:{' '}
                          <span className="font-bold sans-serif">
                            {getParticipantsLeftToAssign(
                              participantsCount,
                              participantsAssigned
                            ) < 0 ? (
                              <>0</>
                            ) : (
                              <>
                                {getParticipantsLeftToAssign(
                                  participantsCount,
                                  participantsAssigned
                                )}
                              </>
                            )}
                          </span>
                        </p>
                        {getParticipantsLeftToAssign(
                          participantsCount,
                          participantsAssigned
                        ) < 0 && (
                          <p className="text-red-500 sans-serif mt-2">
                            Participants overallocated. Please reduce the number
                            of participants.
                          </p>
                        )}
                        {getParticipantsLeftToAssign(
                          participantsCount,
                          participantsAssigned
                        ) === 0 && (
                          <p className="text-green-600 sans-serif mt-2">
                            All participants allocated
                          </p>
                        )}
                      </>
                    )}
                    <div className="flex">
                      {props.availableGrades.map((g, i: number) => {
                        const distribution =
                          props.selection &&
                          props.selection.requestedParticipantDistribution?.find(
                            distribution => distribution?.gradeId === g.id
                          );

                        const isOverridenGrade = distribution?.overrideDate;

                        const value =
                          (isOverridenGrade
                            ? distribution?.overrideParticipants || 0
                            : distribution?.participants) || 0;

                        return (
                          <li
                            className="border-r border-gray-300 py-4 px-6"
                            key={i}
                          >
                            <p className="font-bold sans-serif text-gray-800">
                              {g.name}
                            </p>
                            <>
                              {props.isSubmitted ? (
                                <span>{value}</span>
                              ) : (
                                <input
                                  placeholder="0"
                                  type="number"
                                  min="0"
                                  value={value}
                                  className="w-14 border-gray-300 border rounded p-2"
                                  onChange={e =>
                                    props.onGradeLevelParticipantSelect(
                                      g.id,
                                      e.target.value
                                    )
                                  }
                                  disabled={isCreatedByAdmin || isOverriden}
                                />
                              )}
                            </>
                            {isOverridenGrade && (
                              <div className="text-xs mt-2 text-yellow-600 ">
                                Original: {distribution?.participants || 0}
                              </div>
                            )}
                          </li>
                        );
                      })}
                    </div>
                  </ul>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </Disclosure.Panel>
    </>
  );
};

interface InstituteSelectionProps {
  path: string;
  packageRequestId: string;
}
const InstituteSelection = (props: InstituteSelectionProps) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [instituteSelections, setInstituteSelections] = useState<
    Partial<PackageRequestInstitute>[]
  >([]);
  const [
    instituteSelectionsForDelete,
    setInstituteSelectionsForDelete
  ] = useState<Partial<PackageRequestInstitute>[]>([]);

  const packageContext = useContext(PackageContext);

  const { data, isLoading } = useQuery<{
    state: QueryReturnState;
    packageRequest: PackageRequest;
  }>(
    'packageRequestQuery',
    () => getPackageRequestById(props.packageRequestId),
    {
      onSuccess: data => {
        const preparedInstitutes = data.packageRequest
          .requestedInstitutes as PackageRequestInstitute[];
        setInstituteSelections(preparedInstitutes);
        setIsSubmitted(data.packageRequest.status === 'SUBMITTED');
      }
    }
  );

  const { data: totalsData, isLoading: isTotalsLoading } = useQuery<{
    pdfData: any;
    totals: PackageRequestTotals;
  }>(['packageRequestTotalsQuery', instituteSelections], () =>
    getPackageRequestTotals(props.packageRequestId, {
      requestedInstitutes: instituteSelections
    })
  );

  const isAvailablePlacesRemainingOrOverallocated = instituteSelections.some(
    selection => {
      const participantsCount =
        (selection?.overrideDate
          ? selection.overrideAmount
          : selection?.requestedParticipants) || 0;
      const participantsAssigned = getTotalParticipantsAssigned(
        selection as PackageRequestInstitute
      );
      return (
        getParticipantsLeftToAssign(participantsCount, participantsAssigned) !==
        0
      );
    }
  );

  return (
    <>
      <SEO title="Institute Selection" />
      {isLoading ? <LoadingDialog /> : null}
      <Card>
        {data && data.packageRequest.package && (
          <>
            <h2 className="sans-serif text-primary font-bold text-2xl border-b border-gray-300">
              {data.packageRequest.organization.name} -{' '}
              {data.packageRequest.package.name}
            </h2>
            <h3 className="text-2xl text-gray-600 font-bold mt-4">
              Institute Selection
            </h3>
            <div
              className="mt-4"
              dangerouslySetInnerHTML={{
                __html: data.packageRequest.package.institutesDescription || ''
              }}
            />
            <div className="w-1/2 m-auto mt-6">
              <SelectionInstructions isSubmitted={isSubmitted} />
            </div>
            <div className="mt-4">
              <div className="w-full mt-4">
                {data.packageRequest.package.institutes.map(
                  // @ts-ignore
                  (i: PackageInstitute) => (
                    <div className="mb-3" key={i.id}>
                      <Disclosure>
                        {({ open }) => {
                          return (
                            <InstituteOption
                              title={i.event.name}
                              isSubmitted={isSubmitted}
                              dates={getFirstAndLastDate(
                                (i.event?.isRangeDate
                                  ? ([
                                      i.event.startDate,
                                      i.event.endDate
                                    ] as string[])
                                  : (i.event?.dates as string[])) || []
                              )}
                              price={(i.ticketType?.price || 0) / 100}
                              selection={
                                instituteSelections.find(
                                  institute =>
                                    institute.packageInstituteId === i.id
                                ) as PackageRequestInstitute
                              }
                              selectionOptions={fillRange(
                                i.minAttendees,
                                i.maxAttendees
                              ).map(v => ({ value: v, label: v.toString() }))}
                              availableGrades={i.event.grades as Grade[]}
                              onParticipantsClear={() => {
                                const newInstituteSelection = instituteSelections.find(
                                  el => el.packageInstituteId === i.id
                                );

                                if (newInstituteSelection) {
                                  const existingSelections = instituteSelections.filter(
                                    i =>
                                      i.packageInstituteId !==
                                      newInstituteSelection.packageInstituteId
                                  );

                                  const existingSelectionsForDelete = instituteSelectionsForDelete.filter(
                                    i =>
                                      i.packageInstituteId !==
                                      newInstituteSelection.packageInstituteId
                                  );

                                  setInstituteSelections([
                                    ...existingSelections
                                  ]);

                                  setInstituteSelectionsForDelete([
                                    ...existingSelectionsForDelete,
                                    newInstituteSelection
                                  ]);
                                }
                              }}
                              onParticipantsSelect={v => {
                                packageContext.setHasUnsavedChanges(true);
                                const newInstituteSelection = instituteSelections.find(
                                  el => el.packageInstituteId === i.id
                                ) || {
                                  packageInstituteId: i.id,
                                  createdByInitiator: true,
                                  requestedParticipantDistribution: [],
                                  packageInstitute: i
                                };

                                newInstituteSelection.requestedParticipants =
                                  v?.value;

                                const existingSelections = instituteSelections.filter(
                                  i =>
                                    i.packageInstituteId !==
                                    newInstituteSelection.packageInstituteId
                                );

                                const existingSelectionsForDelete = instituteSelectionsForDelete.filter(
                                  i =>
                                    i.packageInstituteId !==
                                    newInstituteSelection.packageInstituteId
                                );

                                setInstituteSelections([
                                  ...existingSelections,
                                  newInstituteSelection
                                ]);

                                setInstituteSelectionsForDelete([
                                  ...existingSelectionsForDelete
                                ]);
                              }}
                              onGradeLevelParticipantSelect={(
                                gradeId: string,
                                participants: string
                              ) => {
                                packageContext.setHasUnsavedChanges(true);

                                const existingInstituteSelection = instituteSelections.find(
                                  institute =>
                                    institute.packageInstituteId === i.id
                                );

                                const updatedInstitute: Partial<PackageRequestInstitute> = {
                                  ...existingInstituteSelection,
                                  requestedParticipantDistribution: existingInstituteSelection?.requestedParticipantDistribution
                                    ? [
                                        ...(existingInstituteSelection.requestedParticipantDistribution.filter(
                                          gd => gd?.gradeId !== gradeId
                                        ) || []),
                                        ({
                                          gradeId,
                                          participants
                                        } as unknown) as PackageRequestInstituteGrade
                                      ]
                                    : [
                                        ({
                                          gradeId,
                                          participants
                                        } as unknown) as PackageRequestInstituteGrade
                                      ]
                                };

                                setInstituteSelections([
                                  ...instituteSelections.filter(
                                    institute =>
                                      institute.packageInstituteId !== i.id
                                  ),
                                  updatedInstitute
                                ]);
                              }}
                              open={open}
                              complete={false}
                            />
                          );
                        }}
                      </Disclosure>
                    </div>
                  )
                )}
              </div>

              <div className="flex justify-between">
                <PackageCostTotals
                  isLoading={isTotalsLoading}
                  package={data.packageRequest.package}
                  totals={totalsData?.totals}
                />
                <PackageSaveControls
                  packageRequest={data.packageRequest}
                  segment="INSTITUTES"
                  segmentData={instituteSelections}
                  deleteData={instituteSelectionsForDelete}
                  previousRoute={getManageServicesPreviousStep(
                    ManageServicesEnum.INSTITUTE_SELECTION,
                    data.packageRequest
                  )}
                  nextRoute={getManageServicesNextStep(
                    ManageServicesEnum.INSTITUTE_SELECTION,
                    data.packageRequest
                  )}
                  isDisabled={isAvailablePlacesRemainingOrOverallocated}
                />
              </div>
            </div>
          </>
        )}
      </Card>
    </>
  );
};

export default InstituteSelection;
