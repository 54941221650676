import React from 'react';
import { formatAsCurrency } from '../../util';
import classNames from 'classnames';
import { EventSectionsCartCouponUseModel } from '../event-sections/EventSectionsForm';

export const CouponInfo = ({
  couponUse,
  removeBtn,
  className = ''
}: {
  couponUse: EventSectionsCartCouponUseModel;
  removeBtn?: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={classNames({ 'font-semibold': true }, className)}>
      <div className="text-primary-dark flex gap-4">
        <span>Coupon applied: {couponUse?.coupon?.code}</span>
        {removeBtn}
      </div>
      <div className="text-primary-dark">
        Discount:{' '}
        {couponUse?.coupon?.type === 'FIXED'
          ? formatAsCurrency((couponUse.coupon.discountAmount || 0) / 100)
          : `${couponUse?.coupon?.discountPercentage}%`}
      </div>
    </div>
  );
};
