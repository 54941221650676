import { navigate } from 'gatsby';
import React from 'react';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import PurchaseOrderForm, {
  PurchaseOrdersSubmitForm
} from '../../common/PurchaseOrderForm';
import { getEventPayment, submitPurchaseOrderForInstitute } from '../api';
import { FETCH_STATES, useFetch } from '../../../hooks/useFetch';
import SEO from '../../Seo';
import Card from '../../common/Card';
import { useQuery } from 'react-query';
import { CouponInfo } from '../CouponInfo';

interface EventApplicationCheckoutPurchaseOrderProps {
  path: string;
}

const EventApplicationCheckoutPurchaseOrder = ({}: EventApplicationCheckoutPurchaseOrderProps) => {
  const { eventPaymentId } = queryString.parse(location.search);
  if (!eventPaymentId) {
    navigate(`/members/events/applications`);
  }

  const { apply, error: submitError, state } = useFetch(
    submitPurchaseOrderForInstitute
  );

  const { data } = useQuery('eventPayment', () =>
    getEventPayment(eventPaymentId as string)
  );

  const handleSubmit = async (values: PurchaseOrdersSubmitForm) => {
    const res = await apply({
      ...values,
      eventPaymentId,
      type: 'EVENT_APPLICATION'
    });
    if (res.state === 'SUCCESS') {
      navigate(
        `/members/events/applications/checkout/success?eventPaymentId=${eventPaymentId}&source=purchase-order`
      );
    } else {
      toast.error(submitError);
    }
  };

  return (
    <>
      <SEO title="Purchase Orders Submit" />
      <Card className="mt-4">
        {data?.eventPayment?.couponUse && (
          <div className="mb-3">
            <CouponInfo couponUse={data.eventPayment.couponUse} />
            <hr />
          </div>
        )}
        <h3 className="text-lg mb-2">Submit Purchase Order</h3>
        <PurchaseOrderForm
          handleSubmit={handleSubmit}
          onCancel={() => navigate(`/members/events/applications`)}
          isLoading={state === FETCH_STATES.PROCESSING}
        />
      </Card>
    </>
  );
};

export default EventApplicationCheckoutPurchaseOrder;
