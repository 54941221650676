import React from 'react';
import Card from '../../common/Card';
import EventApplicationField from './EventApplicationField';
import { QuestionType } from './EventApplicationQuestionnaire';

interface EventApplicationQuestionnaireProps {
  application: any;
}

const EventApplicationQuestionnairePreview = (
  props: EventApplicationQuestionnaireProps
) => {
  const { application } = props;
  return (
    <Card className="shadow-none">
      <div className="mb-4">
        {(application.questionnaire?.length
          ? application.questionnaire
          : []
        ).map((question: any, index: number) => (
          <div key={index} className="mb-4">
            <EventApplicationField
              key={question.eventApplicationQuestionId}
              label={question.label}
            >
              {question.type === QuestionType.CHECKBOX ? (
                <p>
                  {Object.keys(question.value)
                    .filter((key: string) => question.value[key])
                    .map((key: string) => `- ${key} \n`)}
                </p>
              ) : (
                question.value
              )}
            </EventApplicationField>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default EventApplicationQuestionnairePreview;
