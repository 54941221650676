import classnames from 'classnames';
import React from 'react';

interface EventApplicationReviewSubmitProps {
  title: string;
  description?: string;
  size?: 'md' | 'xs';
  color?: 'gray' | 'primary';
}

const EventApplicationHeader = (props: EventApplicationReviewSubmitProps) => {
  const { title, description, size, color = 'primary' } = props;

  const classNamesForHeader = classnames({
    'font-bold text-xl': size === 'xs',
    'text-primary': color === 'primary',
    'text-gray': color === 'gray'
  });

  return (
    <div className="mt-4">
      {size === 'xs' ? (
        <>
          <h3 className={classNamesForHeader}>{title}</h3>
          {description && (
            <h4 className="text-gray-600 mt-1 text-lg">{description}</h4>
          )}
        </>
      ) : (
        <>
          <h2 className={classNamesForHeader}>{title}</h2>
          {description && (
            <h3 className="text-gray-600 mt-1 text-lg">{description}</h3>
          )}
        </>
      )}
    </div>
  );
};

export default EventApplicationHeader;
