import React from 'react';

interface ProfilePhotoProps {
  photo: string;
}
const ProfilePhoto = (props: ProfilePhotoProps) => {
  return (
    <img
      src={props.photo}
      className="rounded-full border-primary border-2 m-auto"
    />
  );
};

export default ProfilePhoto;
