import { useFormik } from 'formik';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';
import React, { useContext, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { countries } from '../../../data/countries';
import Card from '../../common/Card';
import LoadingDialog from '../../common/LoadingDialog';
import * as Yup from 'yup';
import { getEventApplication, updateEventApplication } from './../api';
import ApplicationSaveControls, {
  ApplicationSaveTypeEnum
} from './../ApplicationSaveControls';
import { FormField, FormWrapper } from '../../common/Form';
import EventApplicationHeader from './EventApplicationHeader';
import { states } from '../../../data/states';
import { EventManagementContext } from '../EventManagement';

export const getEventApplicationGeneralInfo = (
  application: any,
  onlyFromApplication?: boolean
) => {
  const generalInfoFields = [
    'firstName',
    'lastName',
    'address1',
    'address2',
    'city',
    'stateProvince',
    'zipPostal',
    'country',
    'phone'
  ];
  const keys = Object.keys(application);

  const generalInfoFromApplication: { [x: string]: string } = {};

  keys.forEach(k => {
    if (generalInfoFields.includes(k)) {
      generalInfoFromApplication[k] = application[k];
    }
  });

  if (
    generalInfoFields.some(f => !!generalInfoFromApplication[f]) ||
    onlyFromApplication
  ) {
    return generalInfoFromApplication;
  } else {
    return application.user;
  }
};

const ApplicationGeneralInfoSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  address1: Yup.string().required('Address is required'),
  address2: Yup.string(),
  city: Yup.string().required('City is required'),
  stateProvince: Yup.string().required('State / Province is required'),
  zipPostal: Yup.string()
    .max(10, 'Zip Postal must be at most 10 characters')
    .nullable(),
  country: Yup.string().required('Country is required'),
  phone: Yup.string()
    .test('validate-phone', 'Phone is invalid', (value: any) => {
      if (!value) return false;
      return value.indexOf('_') === -1;
    })
    .required('Phone is required')
});

export type ApplicationGeneralInfoFormForm = {
  firstName: string;
  lastName: string;
  address1: string;
  address2?: string;
  city: string;
  stateProvince: string;
  zipPostal?: string;
  country: string;
  phone: string;
};

type IForm = ApplicationGeneralInfoFormForm;

interface EventApplicationGeneralInformationProps {
  path: string;
  children: React.ReactChildren;
  applicationId: string;
}

const EventApplicationGeneralInformation = (
  props: EventApplicationGeneralInformationProps
) => {
  const { setIsGeneralInformationFilled } = useContext(EventManagementContext);
  const [submitting, setSubmitting] = useState<ApplicationSaveTypeEnum | null>(
    null
  );

  const formik = useFormik<IForm>({
    initialValues: {
      firstName: '',
      lastName: '',
      address1: '',
      address2: '',
      city: '',
      stateProvince: '',
      zipPostal: '',
      country: '',
      phone: ''
    },
    validationSchema: ApplicationGeneralInfoSchema,
    onSubmit: values => {}
  });

  const { data, isLoading, error } = useQuery(
    ['eventApplication'],
    () => getEventApplication(props.applicationId),
    {
      onSuccess: data => {
        const generalInfo = getEventApplicationGeneralInfo(data.application);
        formik.setValues(
          {
            firstName: generalInfo.firstName || '',
            lastName: generalInfo.lastName || '',
            address1: generalInfo.address1 || '',
            address2: generalInfo.address2 || '',
            city: generalInfo.city || '',
            stateProvince: generalInfo.stateProvince || '',
            zipPostal: generalInfo.zipPostal || '',
            country: generalInfo.country || 'United States',
            phone: generalInfo.phone || ''
          },
          true
        );
      }
    }
  );

  const mutation = useMutation(
    ['saveGeneralInformation'],
    updateEventApplication
  );

  const save = async (shouldNavigate = false) => {
    const values = formik.values;
    setSubmitting(
      shouldNavigate
        ? ApplicationSaveTypeEnum.SAVE_AND_CONTINUE
        : ApplicationSaveTypeEnum.SAVE
    );
    await mutation.mutate(
      {
        segment: 'GENERAL_INFORMATION',
        applicationId: props.applicationId,
        data: values
      },
      {
        onSuccess: data => {
          if (data.state === 'BAD_REQUEST') {
            toast.error(data.message || 'Something went wrong');
          } else {
            setIsGeneralInformationFilled(true);
            toast.success('Your application has been saved');
            if (shouldNavigate) {
              navigate(`../affiliations-and-attendance`);
            }
          }
          setSubmitting(null);
        }
      }
    );
  };

  if (isLoading) return <LoadingDialog />;
  return (
    <>
      <Card>
        <EventApplicationHeader
          title="General Information"
          description={data.application.event.name}
        />
        <section>
          <FormWrapper
            formik={formik}
            hideActionButtons={true}
            isLoading={!!submitting}
          >
            <div className="flex">
              <div className="w-1/2 mr-8">
                <div className="flex mb-4">
                  <div className="w-1/2 mr-2">
                    <FormField<IForm> name="firstName" type="text" />
                  </div>
                  <div className="w-1/2 ml-2">
                    <FormField<IForm> name="lastName" type="text" />
                  </div>
                </div>
                <div className="mb-4">
                  <FormField<IForm>
                    label="Address"
                    name="address1"
                    type="text"
                  />
                </div>
                <div className="mb-4">
                  <FormField<IForm>
                    label="Address (con't)"
                    name="address2"
                    type="text"
                  />
                </div>
                <div className="flex mb-4">
                  <div className="w-2/3 mr-1">
                    <FormField<IForm> name="city" type="text" />
                  </div>
                  <div className="w-1/3 ml-1">
                    {formik.values.country !== 'United States' ? (
                      <FormField<IForm> name="stateProvince" type="text" />
                    ) : (
                      <FormField<IForm>
                        name="stateProvince"
                        label="State"
                        placeholder="State"
                        type="select"
                        options={states.map(s => ({
                          label: s.name,
                          value: s.value
                        }))}
                      />
                    )}
                  </div>
                </div>
                <div className="flex mb-4">
                  <div className="w-1/2 mr-1">
                    <FormField<IForm> name="zipPostal" type="text" />
                  </div>
                  <div className="w-1/2 ml-1">
                    <FormField<IForm>
                      name="country"
                      type="select"
                      onChange={v => {
                        formik.setFieldValue('stateProvince', '', true);
                      }}
                      options={countries.map(c => ({
                        label: c.name,
                        value: c.name
                      }))}
                    />
                  </div>
                </div>
                <div className="mb-8">
                  <FormField<IForm>
                    name="phone"
                    placeholder="(555) 555-5555"
                    type="mask"
                    mask="phoneNumber"
                  />
                </div>
              </div>
            </div>
            <ApplicationSaveControls
              loadingType={submitting}
              isDisabled={!formik.isValid}
              saveApplication={() => save()}
              saveApplicationAndContinue={() => save(true)}
            />
          </FormWrapper>
        </section>
      </Card>
    </>
  );
};

export default EventApplicationGeneralInformation;
