import {
  faPlus,
  faTimes,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { countries } from '../../data/countries';
import { states } from '../../data/states';
import { FormField, FormWrapper } from '../common/Form';
import { useQuery } from 'react-query';
import { SEARCH_DEBOUNCE_TIME } from '../../constants/input';
import { debounce } from 'lodash';
import { Organization } from '../members/NewApiTypes.generated';
import { getOrganizations } from '../members/api';
import {
  CustomDropdownOption,
  CustomDropdownOptionType
} from '../common/CustomDropdownOption';

export type CreateUserType = {
  firstName: string;
  lastName: string;
  email: string;
  organization: { value: string; label: string } | undefined;
};

type IForm = CreateUserType;

const CreateUserSchema: Yup.SchemaOf<CreateUserType> = Yup.object().shape({
  firstName: Yup.string().required('First name required'),
  lastName: Yup.string().required('Last name required'),
  email: Yup.string().required('Email required'),
  organization: Yup.object()
    .shape({
      value: Yup.string().required(),
      label: Yup.string().required()
    })
    .default(undefined)
    .required('Organization required')
});

interface CreateOrganizationModalProps {
  onSubmit: (values: CreateUserType) => void;
  onCancel: () => void;
  isLoading: boolean;
}

const CreateUserModal = (props: CreateOrganizationModalProps) => {
  const [isOrganizationLoading, setOrganizationLoading] = useState(false);

  const formik = useFormik<CreateUserType>({
    validationSchema: CreateUserSchema,
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      organization: undefined
    },
    onSubmit: values => {
      props.onSubmit(values);
    }
  });

  const loadOrganizationOptions = async (
    inputValue: string,
    callback: (options: Array<CustomDropdownOptionType>) => void
  ) => {
    setOrganizationLoading(true);
    const result = await getOrganizations({ search: inputValue });
    const organizations = result.organizations as Organization[];
    setOrganizationLoading(false);
    callback(
      organizations.map(o => ({
        value: o.id,
        label: o.name,
        description: o.district
      }))
    );
  };

  return (
    <>
      <div
        className="h-screen w-0  sm:w-1/4 md:w-1/2 lg:w-2/3 fixed bg-black opacity-30 z-10 top-0 bottom-0 left-0 overflow-hidden"
        onClick={props.onCancel}
      />
      <aside className="w-full sm:w-3/4 md:w-1/2 lg:w-1/3 shadow-2xl fixed top-0 bottom-0 right-0 bg-white pt-4 overflow-scroll z-10">
        <FormWrapper
          className="px-8 py-4"
          formik={formik}
          onCancel={props.onCancel}
          isLoading={props.isLoading}
        >
          <FormField<IForm> name="firstName" type="text" required />
          <FormField<IForm> name="lastName" type="text" required />
          <FormField<IForm> name="email" type="email" required />
          <FormField<IForm>
            name="organization"
            type="async-select"
            loadOptions={debounce(
              loadOrganizationOptions,
              SEARCH_DEBOUNCE_TIME
            )}
            onChange={data => formik.setFieldValue('organization', data, true)}
            isLoading={isOrganizationLoading}
            required
            components={{ Option: CustomDropdownOption as any }}
          />
        </FormWrapper>
      </aside>
    </>
  );
};

export default CreateUserModal;
