import React from 'react';
import AcceptDistrictInvitation from './AcceptDistrictInvitation';

type AcceptDistrictInvitationWithoutCodeProps = {
  path: string;
};
const AcceptDistrictInvitationWithoutCode = (
  props: AcceptDistrictInvitationWithoutCodeProps
) => {
  return <AcceptDistrictInvitation />;
};

export default AcceptDistrictInvitationWithoutCode;
