import { Disclosure } from '@headlessui/react';
import { format } from 'date-fns';
import * as _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  EventTicketRequestEvent,
  User
} from '../members/NewApiTypes.generated';
import { UserOptionType } from '../members/AssignTickets';
import { EventTicketAssignment } from './EventAssignTickets';
import EventTicketPanel from './EventTicketPanel';
import {
  getTicketAllocationNotAvailableMessage,
  isNotAvailableForPurchase
} from '../../helpers/eventSections.helper';
import { getUserInfo } from '../../util/auth';
import { formatDates } from '../../util/date';

interface AllocateEventsTicketsProps {
  organizationId: string;
  eventTicketRequestEvents: EventTicketRequestEvent[];
  packageRequestOrganizationUsers: (User & { isPending?: boolean })[];
  tickets: EventTicketAssignment[];
  onUpdateEventSections: (a: EventTicketAssignment) => void;
  newUserBtn: JSX.Element;
}
const AllocateEventsTickets = (props: AllocateEventsTicketsProps) => {
  const { eventTicketRequestEvents } = props;
  const [user, setUser] = useState();
  useEffect(() => {
    const fetchUser = async () => {
      setUser(await getUserInfo());
    };
    fetchUser();
  }, []);

  return (
    <>
      <div className="mb-4">{props.newUserBtn}</div>

      {eventTicketRequestEvents.map(etr => {
        return (
          <div className="mb-4 flex flex-col" key={etr.id}>
            <Disclosure defaultOpen={true}>
              {({ open }) => {
                const assignmentsCount = etr.numberOfTickets;
                const existingTickets = props.tickets.find(
                  t => t.eventTicketRequestEventId === etr.id
                ) || {
                  eventTicketRequestEventId: etr.id,
                  assignedUsers: Array(assignmentsCount).fill(null)
                };
                const usersOptions =
                  props.packageRequestOrganizationUsers?.map(sd => ({
                    value: sd?.id || '',
                    label:
                      [
                        sd?.firstName,
                        sd?.lastName,
                        sd?.isPending && '(Pending)'
                      ]
                        .filter(Boolean)
                        .join(' ') || '',
                    email: sd?.email || ''
                  })) || [];

                const spotsLack =
                  assignmentsCount - existingTickets.assignedUsers.length;

                if (spotsLack > 0) {
                  existingTickets.assignedUsers.concat(
                    Array(spotsLack).fill(null)
                  );
                }

                return (
                  <EventTicketPanel
                    open={open}
                    title={etr.event.name}
                    ticketTitle={etr.event.name || ''}
                    ticketPrice={etr.eventTicketType?.price || 0}
                    dates={formatDates(etr.event.dates as string[])}
                    actualAmount={assignmentsCount}
                    users={usersOptions}
                    blockApplicantAllocationMessage={
                      isNotAvailableForPurchase({
                        event: etr.event,
                        type: 'SERIES',
                        isImpersonated: (user as any)?.impersonator
                      })
                        ? getTicketAllocationNotAvailableMessage(
                            etr.event.daysBeforePurchaseClose
                          )
                        : undefined
                    }
                    assignments={existingTickets}
                    onAssignmentSelect={userSelect => {
                      const assignedUsers = [...existingTickets.assignedUsers];

                      assignedUsers[userSelect.position] =
                        usersOptions.find(u => u.value === userSelect.userId) ||
                        null;

                      const updatedOrgPackageEvent: EventTicketAssignment = {
                        ...existingTickets,
                        assignedUsers
                      };

                      props.onUpdateEventSections(updatedOrgPackageEvent);
                    }}
                    onClearAssignment={e => {
                      const assignedUsers: (UserOptionType | null)[] = [
                        ...existingTickets.assignedUsers
                      ];

                      if (assignedUsers[e.position]?.value === e.userId) {
                        assignedUsers[e.position] = null;
                      }

                      const updatedOrgPackageEvent: EventTicketAssignment = {
                        ...existingTickets,
                        assignedUsers: assignedUsers as any[]
                      };

                      props.onUpdateEventSections(updatedOrgPackageEvent);
                    }}
                  />
                );
              }}
            </Disclosure>
          </div>
        );
      })}
    </>
  );
};

export default AllocateEventsTickets;
