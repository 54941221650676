import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { AuthContext } from '../../context/AuthContext';
import Button from '../common/Button';
import Card from '../common/Card';
import LoadingDialog from '../common/LoadingDialog';
import SEO from '../Seo';
import { FETCH_STATES, useFetch } from '../../hooks/useFetch';
import queryString from 'query-string';
import {
  Event,
  EventSection,
  EventTicket,
  EventTicketType,
  User
} from '../members/NewApiTypes.generated';
import {
  createUserWithAcceptedInvitation,
  getConferenceDayTicketAssignments,
  getOrganizationMembership,
  submitAssignConferenceDayTickets
} from '../members/api';
import AllocateConferenceDaysTickets from './AllocateConferenceDaysTickets';
import MembersPageTitle from '../members/MembersPageTitle';
import { UserInfo } from '../../context/User';
import { toast } from 'react-toastify';
import CreateUserModal, { CreateUserType } from './CreateUserModal';

const hasMissallocations = (
  ticketsAssignments: TicketAssignments,
  assignedTickets: AssignedTickets
) => {
  if (
    assignedTickets.sections.some(section =>
      ticketsAssignments?.sections.some(
        s =>
          s.id === section.sectionId &&
          section.assignedUsers?.length < s.numberOfTickets
      )
    )
  ) {
    return true;
  }
  return false;
};

export interface ConferenceDaySection {
  event: Event;
  eventSection: EventSection;
  eventSectionId: string;
  eventTicketRequestId: string;
  eventTicketTypeId: string;
  eventTicketType: EventTicketType;
  eventTickets: EventTicket[];
  id: string;
  numberOfTickets: number;
}

export type TicketAssignments = {
  sections: ConferenceDaySection[];
};
interface ConferenceDaysAssignTicketsProps {
  path: string;
}

export type AssignedTickets = {
  sections: EventSectionTicketAssignment[];
};

export type UserOptionType = { value: string; label: string; email: string };

export type EventSectionTicketAssignment = {
  assignedUsers: UserOptionType[];
  sectionId: string;
};

const ConferenceDaysAssignTickets = (
  props: ConferenceDaysAssignTicketsProps
) => {
  const { eventPaymentId, eventId } = queryString.parse(location.search);
  const auth = useContext(AuthContext);

  const { isLoading: isLoadingTicketAssignments, data } = useQuery<any>(
    ['conferenceDayTicketAssignments', { eventPaymentId, eventId }],
    keys =>
      getConferenceDayTicketAssignments(
        keys?.queryKey?.[1]?.eventPaymentId as string,
        keys?.queryKey?.[1]?.eventId as string
      ),
    {
      onSuccess: (result: { sections: ConferenceDaySection[] }) => {
        setAssignedTickets({
          sections:
            result.sections?.map(section => {
              return {
                sectionId: section.id,
                assignedUsers: section.eventTickets.map(ticket => ({
                  value: ticket.user.id,
                  label: [ticket.user.firstName, ticket.user.lastName]
                    .filter(Boolean)
                    .join(' '),
                  email: ticket.user.email
                }))
              };
            }) || []
        });
      }
    }
  );

  const {
    isLoading: isLoadingOrganizationUsers,
    data: organizationUsers,
    refetch: refetchOrganizationUsers
  } = useQuery<{ users: User[]; pendingUsers: User[] }>(
    'packageRequestOrganizationUsers',
    () =>
      getOrganizationMembership(
        (auth.authState as UserInfo).currentOrganizationId
      )
  );
  const [assignedTickets, setAssignedTickets] = useState<AssignedTickets>({
    sections: []
  });

  const [isUserModalOpen, setIsUserModalOpen] = useState(false);

  const { apply: applyAssignConferenceDaysTickets } = useFetch(
    submitAssignConferenceDayTickets
  );

  const { apply: applyCreateUserWithAcceptedInvitation, state } = useFetch(
    createUserWithAcceptedInvitation
  );

  const [loadingSave, setLoadingSave] = useState(false);

  const submitSave = async (data: AssignedTickets) => {
    setLoadingSave(true);
    const res = await applyAssignConferenceDaysTickets(
      { data },
      eventPaymentId
    );
    if (res.state === 'SUCCESS') {
      toast.success('Assignments saved');
      setLoadingSave(false);
      return;
    }
    setLoadingSave(false);
    toast.error(res.error || res.data?.message || 'Something went wrong');
  };

  const isLoading = isLoadingTicketAssignments;

  const openNewUserModal = () => {
    setIsUserModalOpen(true);
  };

  const submitCreateNewUser = async (values: CreateUserType) => {
    const res = await applyCreateUserWithAcceptedInvitation({
      ...values,
      organizationId: values.organization?.value
    });

    if (res.state === 'SUCCESS') {
      toast.success('User created');
      await refetchOrganizationUsers();
      setIsUserModalOpen(false);
      return;
    }

    toast.error(res.error || res.data?.message || 'Something went wrong');
  };

  return (
    <>
      {isUserModalOpen && (
        <CreateUserModal
          onCancel={() => setIsUserModalOpen(false)}
          onSubmit={submitCreateNewUser}
          isLoading={state === FETCH_STATES.PROCESSING}
        />
      )}
      <SEO title="Assign Tickets" />

      <MembersPageTitle title="Assign Tickets" />
      {isLoading && <LoadingDialog />}
      {!isLoading && !isLoadingOrganizationUsers && eventPaymentId && data && (
        <>
          <Card className="mt-4">
            <AllocateConferenceDaysTickets
              newUserBtn={
                <div className="flex justify-end mt-6">
                  <Button
                    onClick={e => {
                      e?.preventDefault();
                      e?.stopPropagation();
                      openNewUserModal();
                    }}
                    text={'+ Add New User'}
                    type="button"
                  />
                </div>
              }
              organizationId={
                (auth.authState as UserInfo).currentOrganizationId
              }
              ticketAssignments={data}
              packageRequestOrganizationUsers={[
                ...(organizationUsers?.users || []),
                ...(organizationUsers?.pendingUsers || []).map(user => ({
                  ...user,
                  isPending: true
                }))
              ]}
              tickets={assignedTickets.sections}
              onUpdateEventSections={a => {
                const eventsAssignedTickets = assignedTickets.sections.filter(
                  section => !(section.sectionId === a.sectionId)
                );

                setAssignedTickets({
                  ...assignedTickets,
                  sections: [...eventsAssignedTickets, a]
                });
              }}
            />
            <div className="flex justify-end mt-6">
              {hasMissallocations(data, assignedTickets) && (
                <p className="sans-serif text-yellow-600 my-auto mr-4">
                  Not all tickets have been allocated
                </p>
              )}
            </div>
            <div className="mt-2 flex justify-end">
              <Button
                text="Save"
                disabled={false}
                isLoading={loadingSave}
                onClick={() => {
                  submitSave({
                    sections: assignedTickets.sections.map(section => ({
                      ...section,
                      assignedUsers: section.assignedUsers.filter(Boolean)
                    }))
                  });
                }}
              />
            </div>
          </Card>
        </>
      )}
    </>
  );
};

export default ConferenceDaysAssignTickets;
