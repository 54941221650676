import React, { useContext, useEffect } from 'react';
import Card from '../common/Card';
import SearchInput from '../SearchInput';
import SEO from '../Seo';
import MembersPageTitle from './MembersPageTitle';
import ResourceItem from './ResourceItem';
import { useQuery } from 'react-query';
import { getFavoritedDocs } from './Queries';
import LoadingDialog from '../common/LoadingDialog';
import { AuthContext } from '../../context/AuthContext';
import { navigate } from '@reach/router';
import { User } from './NewApiTypes.generated';

export type Resource = {
  id: string;
  title: string;
  type: 'video' | 'pdf';
  collection: string;
  collectionSlug: string;
  toolkitGoogleDriveId: string;
};

interface MySavedResourcesProps {
  path: string;
}

const MySavedResources = (props: MySavedResourcesProps) => {
  const auth = useContext(AuthContext);
  const { id: _userId, portalMetadata } = auth.authState as User & {
    portalMetadata: any;
  };

  const userId = portalMetadata?.userId || _userId;

  useEffect(() => {
    if (!auth.canAccessToolkit(auth.authState as User)) {
      navigate('/members/profile');
    }
  }, []);

  const { isFetching, error, data } = useQuery('favoritedDocs', () =>
    getFavoritedDocs(userId)
  );

  return (
    <div>
      <SEO title="My Saved Resources" />
      <div className="mb-4">
        <MembersPageTitle title="My Saved Resources" />
      </div>
      <div className="w-1/3">
        <SearchInput placeholder="Search My Saved Resources" />
      </div>
      <div className="mt-4">
        <Card>
          {isFetching && <LoadingDialog />}
          {!isFetching && (
            <>
              {data && data.length ? (
                <ul>
                  {data.map((r: Resource, i: number) => (
                    <ResourceItem
                      item={r}
                      collectionSlug={r.collectionSlug || ''}
                      key={i}
                      user={auth.authState as User}
                    />
                  ))}
                </ul>
              ) : (
                <p className="sans-serif text-gray-700 text-center">
                  No saved resources found
                </p>
              )}
            </>
          )}
        </Card>
      </div>
    </div>
  );
};

export default MySavedResources;
