import React, { useContext, useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import Button from './common/Button';
import Layout from './Layout';
import ProfilePhoto from './members/ProfilePhoto';
import { AuthContext } from '../context/AuthContext';
import { clearImpersonate, refreshToken } from './members/api/index';
import { useLocation, Redirect, useParams } from '@reach/router';
import { USER_TYPE } from './../context/UserType';
import { User } from './members/NewApiTypes.generated';
import { removeCookie } from '../util/cookie';

type Member = {
  name: string;
  school: string;
  avatar: string;
};

interface MemberSmallProfileProps {
  member: Member;
}

const MemberSmallProfile = (props: MemberSmallProfileProps) => {
  const auth = useContext(AuthContext);
  const logout = async () => {
    try {
      removeCookie('access_token');
      // TODO delete later, left for now so users will clean up local storage
      localStorage.removeItem('eventSectionsCart');

      if (auth.setAuthState) {
        auth.setAuthState(null);
        navigate('/auth/login');
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="flex sm:mt-6">
      <div className="w-20">
        <ProfilePhoto photo={props.member.avatar} />
      </div>
      <div className="ml-3">
        <p className="text-xl font-bold sans-serif text-primary">
          {props.member.name}
        </p>
        <p className="text-sm text-gray-600 sans-serif">
          {props.member.school}
        </p>
        <div className="mt-2">
          <Button
            text="Log Out"
            size="sm"
            icon={faSignOutAlt}
            onClick={logout}
          />
        </div>
      </div>
    </div>
  );
};

type MembersLink = {
  label: string;
  link: string;
  external?: boolean;
  requireToolkit?: boolean;
};

const membersLinks: MembersLink[] = [
  {
    label: 'Profile',
    link: 'profile'
  },
  {
    label: 'Treasure Chest',
    link: 'treasure-chest',
    requireToolkit: true
  },
  {
    label: 'My Saved Resources',
    link: 'my-saved-resources',
    requireToolkit: true
  },
  {
    label: 'Purchase Orders',
    link: 'purchase-orders'
  },
  {
    label: 'PD Services',
    link: 'organization/select'
  },
  {
    label: 'Event Resources',
    link: 'organization-resources'
  },
  {
    label: 'Manage Services',
    link: 'manage-services/select-organization'
  },
  {
    label: 'Manage Events',
    link: 'events/applications'
  },
  // {
  //   label: 'My Event Registrations',
  //   link: 'events/registration/requests'
  // },
  {
    label: 'Event Cart',
    link: 'event-cart/checkout'
  }
];

interface MembersLinkContentProps {
  linkLabel: string;
}

const MembersLinkContent = (props: MembersLinkContentProps) => {
  return (
    <div className="py-4 border-b border-gray-200">
      <p className="text-lg font-bold text-primary sans-serif">
        {props.linkLabel}
      </p>
    </div>
  );
};

interface MembersLinkProps {
  link: MembersLink;
}

const MembersLink = (props: MembersLinkProps) => {
  if (props.link.external) {
    return (
      <a href={props.link.link}>
        <MembersLinkContent linkLabel={props.link.label} />
      </a>
    );
  }
  return (
    <Link to={`/members/${props.link.link}`}>
      <MembersLinkContent linkLabel={props.link.label} />
    </Link>
  );
};

interface MembersSidebarProps {
  user: User;
}
const MembersSidebar = (props: MembersSidebarProps) => {
  const auth = useContext(AuthContext);
  return (
    <div className="h-full p-8 bg-white shadow-lg">
      <div className="mt-2 mb-10">
        <MemberSmallProfile
          member={{
            name: `${props.user.firstName} ${props.user.lastName}`,
            school: '',
            avatar: require(`./../images/user_placeholder.png`)
          }}
        />
      </div>
      {membersLinks.map((link: MembersLink, i: number) => {
        if (link.requireToolkit && !auth.canAccessToolkit(props.user)) {
          return null;
        }
        return <MembersLink link={link} key={i} />;
      })}
    </div>
  );
};

interface LayoutProps {
  children: React.ReactNode;
  user: User;
}

const MembersLayout = (props: LayoutProps) => {
  const auth = useContext(AuthContext);
  const location = useLocation();

  if (!props.user && location.pathname !== '/auth/login') {
    navigate(`/auth/login?intended=${location.pathname}`);
    return null;
  }
  const [impersonationUser, setImpersonationUser] = useState(
    auth.authState?.impersonator
      ? `${auth.authState?.firstName ?? ''} ${auth.authState?.lastName ??
          auth.authState?.email}`
      : null
  );
  useEffect(() => {
    setImpersonationUser(
      auth.authState?.impersonator
        ? `${auth.authState?.firstName ?? ''} ${auth.authState?.lastName ??
            auth.authState?.email}`
        : null
    );
  }, [auth, setImpersonationUser]);
  useEffect(() => {
    window.addEventListener('impersonationUserChanged', () => {
      setImpersonationUser(
        auth.authState?.impersonator
          ? `${auth.authState?.firstName ?? ''} ${auth.authState?.lastName ??
              auth.authState?.email}`
          : null
      );
    });
  }, [auth, setImpersonationUser]);
  return (
    <Layout>
      <div className="flex">
        <div style={{ width: '400px' }}>
          {props.user && <MembersSidebar user={props.user} />}
        </div>
        <div className="flex flex-col w-full">
          {impersonationUser && (
            <div className="flex flex-row top-0 w-full bg-primary py-2.5 pl-2 sm:pl-4 md:pl-10 font-bold text-blue-100 ml-0 sm:text-base md:text-xl ">
              <div>Viewing as: {impersonationUser}</div>

              <Button
                text="Clear Impersonation"
                size="sm"
                className="ml-4 md:ml-12"
                onClick={async () => {
                  try {
                    await clearImpersonate(auth.authState?.impersonator);
                    try {
                      removeCookie('access_token');

                      if (auth.setAuthState) {
                        auth.setAuthState(null);
                        navigate('/auth/login');
                      }
                    } catch (err) {
                      console.log(err);
                    }
                  } catch (e) {
                    console.log(e);
                  }
                }}
              />
            </div>
          )}
          <section className={`p-8 w-full ${impersonationUser ? 'pt-10' : ''}`}>
            {props.children}
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default MembersLayout;
