import React from 'react';

interface EventsProps {
  path: string;
  children: React.ReactChildren;
}

const Events = (props: EventsProps) => {
  return <>{props.children}</>;
};

export default Events;
