import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { AuthContext } from '../../context/AuthContext';
import Button from '../common/Button';
import Card from '../common/Card';
import LoadingDialog from '../common/LoadingDialog';
import SEO from '../Seo';
import { FETCH_STATES, useFetch } from '../../hooks/useFetch';
import queryString from 'query-string';
import {
  EventTicketRequestEvent,
  User
} from '../members/NewApiTypes.generated';
import {
  createUserWithAcceptedInvitation,
  getEventTicketAssignments,
  getOrganizationMembership,
  submitAssignEventTickets
} from '../members/api';
import MembersPageTitle from '../members/MembersPageTitle';
import { UserInfo } from '../../context/User';
import { toast } from 'react-toastify';
import CreateUserModal, {
  CreateUserType
} from '../conference-days/CreateUserModal';
import { UserOptionType } from '../members/AssignTickets';
import AllocateEventsTickets from './AllocateEventsTickets';

const hasMissallocations = (
  ticketsAssignments: EventTicketAssignments,
  assignedTickets: AssignedTickets
) => {
  if (
    assignedTickets.events?.some(event =>
      ticketsAssignments?.eventTicketRequestEvents.some(
        e =>
          e.id === event.eventTicketRequestEventId &&
          event.assignedUsers?.length < e.numberOfTickets
      )
    )
  ) {
    return true;
  }
  return false;
};

export type EventTicketAssignments = {
  eventTicketRequestEvents: EventTicketRequestEvent[];
};

interface EventAssignTicketsProps {
  path: string;
}

export type AssignedTickets = {
  events: EventTicketAssignment[];
};

export type EventTicketAssignment = {
  assignedUsers: UserOptionType[];
  eventTicketRequestEventId: string;
};

const EventAssignTickets = (props: EventAssignTicketsProps) => {
  const { eventPaymentId } = queryString.parse(location.search);
  const auth = useContext(AuthContext);

  const { isLoading: isLoadingTicketAssignments, data } = useQuery<any>(
    ['eventTicketAssignments', { eventPaymentId }],
    keys =>
      getEventTicketAssignments(keys?.queryKey?.[1]?.eventPaymentId as string),
    {
      onSuccess: (result: {
        eventTicketRequestEvents: EventTicketRequestEvent[];
      }) => {
        setAssignedTickets({
          events:
            result.eventTicketRequestEvents?.map(etr => {
              return {
                eventTicketRequestEventId: etr.id,
                assignedUsers: etr.eventTickets.map((ticket: any) => ({
                  value: ticket.user.id,
                  label: [ticket.user.firstName, ticket.user.lastName]
                    .filter(Boolean)
                    .join(' '),
                  email: ticket.user.email
                }))
              };
            }) || []
        });
      }
    }
  );

  const {
    isLoading: isLoadingOrganizationUsers,
    data: organizationUsers,
    refetch: refetchOrganizationUsers
  } = useQuery<{ users: User[]; pendingUsers: User[] }>(
    'getOrganizationMembership',
    () =>
      getOrganizationMembership(
        (auth.authState as UserInfo).currentOrganizationId
      )
  );
  const [assignedTickets, setAssignedTickets] = useState<AssignedTickets>({
    events: []
  });

  const [isUserModalOpen, setIsUserModalOpen] = useState(false);

  const { apply: applyAssignEventTickets } = useFetch(submitAssignEventTickets);

  const { apply: applyCreateUserWithAcceptedInvitation, state } = useFetch(
    createUserWithAcceptedInvitation
  );

  const [loadingSave, setLoadingSave] = useState(false);

  const submitSave = async (data: AssignedTickets) => {
    setLoadingSave(true);
    const res = await applyAssignEventTickets({ data }, eventPaymentId);
    if (res.state === 'SUCCESS') {
      toast.success('Assignments saved');
      setLoadingSave(false);
      return;
    }
    setLoadingSave(false);
    toast.error(res.error || res.data?.message || 'Something went wrong');
  };

  const isLoading = isLoadingTicketAssignments;

  const openNewUserModal = () => {
    setIsUserModalOpen(true);
  };

  const submitCreateNewUser = async (values: CreateUserType) => {
    const res = await applyCreateUserWithAcceptedInvitation({
      ...values,
      organizationId: values.organization?.value
    });

    if (res.state === 'SUCCESS') {
      toast.success('User created');
      await refetchOrganizationUsers();
      setIsUserModalOpen(false);
      return;
    }

    toast.error(res.error || res.data?.message || 'Something went wrong');
  };

  return (
    <>
      {isUserModalOpen && (
        <CreateUserModal
          onCancel={() => setIsUserModalOpen(false)}
          onSubmit={submitCreateNewUser}
          isLoading={state === FETCH_STATES.PROCESSING}
        />
      )}
      <SEO title="Assign Tickets" />

      <MembersPageTitle title="Assign Tickets" />
      {isLoading && <LoadingDialog />}
      {!isLoading && !isLoadingOrganizationUsers && eventPaymentId && data && (
        <>
          <Card className="mt-4">
            <AllocateEventsTickets
              newUserBtn={
                <div className="flex justify-end mt-6">
                  <Button
                    onClick={e => {
                      e?.preventDefault();
                      e?.stopPropagation();
                      openNewUserModal();
                    }}
                    text={'+ Add New User'}
                    type="button"
                  />
                </div>
              }
              organizationId={
                (auth.authState as UserInfo).currentOrganizationId
              }
              eventTicketRequestEvents={data?.eventTicketRequestEvents || []}
              packageRequestOrganizationUsers={[
                ...(organizationUsers?.users || []),
                ...(organizationUsers?.pendingUsers || []).map(user => ({
                  ...user,
                  isPending: true
                }))
              ]}
              tickets={assignedTickets.events}
              onUpdateEventSections={a => {
                const eventsAssignedTickets = assignedTickets.events.filter(
                  event =>
                    !(
                      event.eventTicketRequestEventId ===
                      a.eventTicketRequestEventId
                    )
                );

                setAssignedTickets({
                  ...assignedTickets,
                  events: [...eventsAssignedTickets, a]
                });
              }}
            />
            <div className="flex justify-end mt-6">
              {hasMissallocations(data, assignedTickets) && (
                <p className="sans-serif text-yellow-600 my-auto mr-4">
                  Not all tickets have been allocated
                </p>
              )}
            </div>
            <div className="mt-2 flex justify-end">
              <Button
                text="Save"
                disabled={false}
                isLoading={loadingSave}
                onClick={() => {
                  submitSave({
                    events: assignedTickets.events.map(event => ({
                      ...event,
                      assignedUsers: event.assignedUsers.filter(Boolean)
                    }))
                  });
                }}
              />
            </div>
          </Card>
        </>
      )}
    </>
  );
};

export default EventAssignTickets;
