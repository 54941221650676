import React, { useContext } from 'react';
import Button from '../common/Button';
import { ApplicationContext } from '../../context/ApplicationContext';

export enum ApplicationSaveTypeEnum {
  SAVE = 'SAVE',
  SAVE_AND_CONTINUE = 'SAVE_AND_CONTINUE'
}
interface ApplicationSaveControls {
  saveApplication: any;
  saveApplicationAndContinue: any;
  previousRoute?: string;
  loadingType?: ApplicationSaveTypeEnum | null;
  isDisabled?: boolean;
  isDisabledContinue?: boolean;
}

const ApplicationSaveControls = (props: ApplicationSaveControls) => {
  const applicationContext = useContext(ApplicationContext);
  return (
    <div className="my-auto flex flex-col items-end">
      {props.previousRoute && (
        <button
          onClick={() => {
            if (applicationContext.hasUnsavedChanges) {
              if (
                confirm(
                  'You have unsaved changes that will be lost. Are you sure you want to navigate away?'
                )
              ) {
                console.log('needs navigate');
              }
            } else {
              console.log('needs navigate');
            }
          }}
          className="text-primary hover:underline text-lg mr-10 mb-4"
        >
          &larr; Previous
        </button>
      )}
      <div className="my-auto flex">
        <div className="mr-4">
          <Button
            text="Save"
            onClick={() => {
              props.saveApplication();
              applicationContext.setHasUnsavedChanges(false);
            }}
            isLoading={props.loadingType === ApplicationSaveTypeEnum.SAVE}
            disabled={props.isDisabled}
          />
        </div>
        <div className="mr-4">
          <Button
            text="Save & Continue"
            onClick={() => {
              props.saveApplicationAndContinue();
              applicationContext.setHasUnsavedChanges(false);
            }}
            isLoading={
              props.loadingType === ApplicationSaveTypeEnum.SAVE_AND_CONTINUE
            }
            disabled={props.isDisabled || props.isDisabledContinue}
          />
        </div>
      </div>
    </div>
  );
};

export default ApplicationSaveControls;
