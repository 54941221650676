import React from 'react';

import { IBillingContact } from './../NewApiTypes';
import { FormWrapper, FormField } from '../../common/Form';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { countries } from '../../../data/countries';
import { states } from '../../../data/states';

export type OrganizationEditBillingContactType = {
  firstName: string;
  lastName: string;
  email: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  stateProvince: string;
  country: string;
  zipPostal: string;
  phone: string;
};
const OrganizationEditBillingContactSchema: Yup.SchemaOf<OrganizationEditBillingContactType> =
  Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email().required('Email is required'),
    addressLine1: Yup.string().required('Address 1 is required'),
    addressLine2: Yup.string(),
    city: Yup.string().required('City is required'),
    stateProvince: Yup.string().required('State / Province is required'),
    country: Yup.string().required('Country is required'),
    zipPostal: Yup.string().required('Zip / Postal code is required'),
    phone: Yup.string().required('Phone is required')
  });
const OrganizationEditBillingContactForm = ({
  cancelEdit,
  onSubmit,
  loading,
  billingContact
}: {
  cancelEdit: any;
  billingContact?: IBillingContact;
  loading: boolean;
  onSubmit: (values: OrganizationEditBillingContactType) => Promise<void>;
}) => {
  const formik = useFormik<OrganizationEditBillingContactType>({
    validationSchema: OrganizationEditBillingContactSchema,
    initialValues: {
      firstName: billingContact?.firstName || '',
      lastName: billingContact?.lastName || '',
      email: billingContact?.email || '',
      addressLine1: billingContact?.addressLine1 || '',
      addressLine2: billingContact?.addressLine2 || '',
      city: billingContact?.city || '',
      stateProvince: billingContact?.stateProvince || '',
      country: billingContact?.country || 'United States',
      zipPostal: billingContact?.zipPostal || '',
      phone: billingContact?.phone || ''
    },
    onSubmit
  });

  return (
    <FormWrapper
      formik={formik}
      isLoading={loading}
      onCancel={cancelEdit}
      className="mt-8"
    >
      <FormField<OrganizationEditBillingContactType>
        name="firstName"
        type="text"
      />
      <FormField<OrganizationEditBillingContactType>
        name="lastName"
        type="text"
      />
      <FormField<OrganizationEditBillingContactType>
        name="email"
        type="email"
      />
      <FormField<OrganizationEditBillingContactType>
        name="addressLine1"
        type="text"
        label="Address 1"
        placeholder="Address 1"
      />
      <FormField<OrganizationEditBillingContactType>
        name="addressLine2"
        type="text"
        label="Address 2"
        placeholder="Address 2"
      />
      <FormField<OrganizationEditBillingContactType> name="city" type="text" />

      {formik.values.country !== 'United States' ? (
        <FormField<OrganizationEditBillingContactType>
          name="stateProvince"
          type="text"
        />
      ) : (
        <FormField<OrganizationEditBillingContactType>
          name="stateProvince"
          label="State"
          placeholder="State"
          type="select"
          options={states.map(s => ({
            label: s.name,
            value: s.value
          }))}
        />
      )}
      <FormField<OrganizationEditBillingContactType>
        name="country"
        type="select"
        options={countries.map(c => ({
          label: c.name,
          value: c.name
        }))}
        onChange={v => {
          if (v.value !== 'United States') {
            formik.setFieldValue('stateProvince', '');
          }
        }}
      />
      <FormField<OrganizationEditBillingContactType>
        name="zipPostal"
        label="Zip / Postal Code"
        type="text"
      />
      <FormField<OrganizationEditBillingContactType>
        name="phone"
        type="mask"
        mask="phoneNumber"
      />
    </FormWrapper>
  );
};

export default OrganizationEditBillingContactForm;
