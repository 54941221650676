import React from 'react';

interface LabelProps {
  text: string;
}

const Label = (props: LabelProps) => {
  return (
    <label className="text-sm text-gray-500 uppercase font-bold sans-serif">
      {props.text}
    </label>
  );
};

export default Label;
