import React from 'react';
import AcceptOrganizationInvitation from './AcceptOrganizationInvitation';

type AcceptOrganizationInvitationWithCodeProps = {
  path: string;
  code?: string;
};
const AcceptOrganizationInvitationWithCode = (
  props: AcceptOrganizationInvitationWithCodeProps
) => {
  const { code } = props;
  return <AcceptOrganizationInvitation code={code} />;
};

export default AcceptOrganizationInvitationWithCode;
