import { Link } from 'gatsby';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { formatDate, formatDateWithoutTimezone } from '../../util';
import Button from '../common/Button';
import Card from '../common/Card';
import LoadingDialog from '../common/LoadingDialog';
import SEO from './../Seo';
import { getAttendance, getSection, updateAttendance } from './Queries';
import { useLocation } from '@reach/router';
import queryString from 'query-string';

interface AttendanceSectionProps {
  path: string;
  eventId: string;
  sectionId: string;
}

const AttendanceSection = (props: AttendanceSectionProps) => {
  const location = useLocation();
  const [attendees, setAttendees] = useState([]);
  const [attendeeUpdate, setAttendeeUpdate] = useState([]);

  const mutation = useMutation(data => updateAttendance(data), {
    onSuccess: data => {
      setAttendeeUpdate([]);
      toast.success('Attendance updated');
    },
    onError: err => {
      toast.error('Something went wrong');
    }
  });

  const {
    isFetching: isAttendanceFetching,
    error: attendanceError,
    data: attendanceData
  } = useQuery('attendance', () => getAttendance(Number(props.sectionId)), {
    onSuccess: attendees => setAttendees(attendees)
  });

  const {
    isFetching: isSectionFetching,
    error: sectionError,
    data: sectionData
  } = useQuery('section', () => getSection(Number(props.sectionId)));

  const queryParams = queryString.parse(location.search);

  return (
    <Card>
      <SEO title={`Attendance Section`} />
      <Link
        to={`/members/events/${props.eventId}/attendance`}
        className="text-primary sans-serif hover:underline mb-4"
      >
        &larr; Back
      </Link>
      {(isAttendanceFetching || isSectionFetching) && <LoadingDialog />}
      {!isAttendanceFetching &&
        sectionData &&
        attendees &&
        !attendees.length && (
          <p className="italic sans-serif text-gray-800 mt-4">
            No attendance to manage
          </p>
        )}
      {sectionData && attendees && !!attendees.length && (
        <div className="mt-4">
          <div className="border-b border-gray-300 mb-4 flex pb-2">
            {queryParams.advanced === 'true' ? (
              <span className="bg-primary rounded-full text-blue-100 py-1 px-3 sans-serif text-xs mx-2 mr-2 my-auto">
                Advanced
              </span>
            ) : (
              <span className="rounded-full border border-primary text-primary py-1 px-3 sans-serif text-xs mx-2 mr-2 my-auto">
                First Year
              </span>
            )}
            <h2 className="sans-serif text-primary font-bold text-2xl">
              {sectionData.AdminTitle} - {sectionData.Event.ShortName}
            </h2>
          </div>
          <div className="my-6">
            <p className="mb-2 sans-serif text-primary text-lg font-bold">
              Today is {formatDateWithoutTimezone(new Date(), false)}
            </p>
            <p className="text-gray-700 text-lg sans-serif">
              Please mark attendance for this section and save when complete.
            </p>
          </div>
          <ul>
            {attendees
              .sort((a, b) => a.User.FirstName.localeCompare(b.User.FirstName))
              .map((attendee: any, i: number) => (
                <li
                  className="mb-2 py-1 border-gray-200 border-b text-lg text-primary leading-8 flex"
                  key={i}
                >
                  <div className="w-6 my-auto">
                    <input
                      className="cursor-pointer h-4 w-4 my-auto"
                      type="checkbox"
                      checked={attendee.Attended}
                      onChange={e => {
                        const updatedAttendee = {
                          ...attendee,
                          Attended: e.target.checked
                        };

                        const update = attendees.map(a => {
                          if (
                            attendee.EventDailyAttendanceID ===
                            a.EventDailyAttendanceID
                          ) {
                            return updatedAttendee;
                          }
                          return a;
                        });

                        setAttendees(update);
                        setAttendeeUpdate([
                          ...attendeeUpdate.filter(
                            update =>
                              update.EventDailyAttendanceID !==
                              updatedAttendee.EventDailyAttendanceID
                          ),
                          updatedAttendee
                        ]);
                      }}
                    />
                  </div>

                  <div>
                    <span className="sans-serif ml-2 my-auto">
                      {attendee.User.FullName}
                    </span>
                    <span className="text-sm sans-serif text-gray-600 ml-2">
                      ({attendee.User.Email})
                    </span>
                  </div>
                </li>
              ))}
          </ul>
          <div className="mt-4">
            <Button
              text="Save"
              isLoading={mutation.isLoading}
              onClick={() =>
                mutation.mutate({
                  EventID: Number(props.eventId),
                  SectionID: Number(props.sectionId),
                  EventDailyAttendances: attendeeUpdate
                })
              }
            />
          </div>
        </div>
      )}
    </Card>
  );
};

export default AttendanceSection;
