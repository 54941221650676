import React from 'react';
import { useQuery } from 'react-query';
import queryString from 'query-string';
import { getEventPayment } from '../members/api';
import ConferenceDaysAssignTickets from '../conference-days/ConferenceDaysAssignTickets';
import EventAssignTickets from './EventAssignTickets';

const EventAssignTicketsWrapper = (props: any) => {
  const { eventPaymentId } = queryString.parse(location.search);

  const { data } = useQuery<any>(
    ['getEventPayment', { eventPaymentId }],
    keys => getEventPayment(eventPaymentId as string)
  );

  if (
    data?.eventPayment?.purchaseOrders?.some(
      (po: any) => po.status === 'VOIDED'
    ) ||
    data?.eventPayment?.status === 'CANCELLED'
  ) {
    return (
      <>
        <p className="w-full text-center py-5 italic text-primary sans-serif">
          The event payment has been voided or cancelled.
        </p>
      </>
    );
  }

  return (
    <>
      {data?.eventPayment?.ticketRequest?.ticketRequestSections?.length ? (
        <ConferenceDaysAssignTickets {...props} />
      ) : (
        <EventAssignTickets {...props} />
      )}
    </>
  );
};

export default EventAssignTicketsWrapper;
