import React from 'react';

interface InfoBlockProps {
  title: string;
  info: string[];
  infoClassNames?: string[];
}

const InfoBlock = (props: InfoBlockProps) => {
  const filteredInfo = props.info.filter((item: string) => !!item);
  return (
    <div>
      <label className="uppercase text-gray-500 text-sm sans-serif">
        {props.title}
      </label>
      <div className="mt-1">
        {!filteredInfo.length ? (
          <p className="text-gray-400 sans-serif italic">Not set</p>
        ) : (
          <>
            {filteredInfo.map((item: string, i: number) => (
              <p
                className={`${
                  props.infoClassNames?.[i] ?? ''
                }  text-gray-700 sans-serif`}
                key={i}
              >
                {item}
              </p>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default InfoBlock;
