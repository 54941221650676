import React from 'react';

import { IOrganizationAddress } from './../NewApiTypes';
import { FormWrapper, FormField } from '../../common/Form';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { countries } from '../../../data/countries';
import { states } from '../../../data/states';

export type OrganizationEditAddressType = {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  stateProvince: string;
  country: string;
  zipPostal: string;
  phone: string;
};
const OrganizationEditAddressSchema: Yup.SchemaOf<OrganizationEditAddressType> =
  Yup.object().shape({
    addressLine1: Yup.string().required('Address 1 is required'),
    addressLine2: Yup.string(),
    city: Yup.string().required('City is required'),
    stateProvince: Yup.string().required('State / Province is required'),
    country: Yup.string().required('Country is required'),
    zipPostal: Yup.string().required('Zip / Postal code is required'),
    phone: Yup.string().required('Phone is required')
  });

const OrganizationEditAddressForm = ({
  cancelEdit,
  onSubmit,
  loading,
  address
}: {
  cancelEdit: any;
  address: IOrganizationAddress;
  onSubmit: (values: OrganizationEditAddressType) => Promise<void>;
  loading: boolean;
}) => {
  const formik = useFormik<OrganizationEditAddressType>({
    validationSchema: OrganizationEditAddressSchema,
    initialValues: {
      addressLine1: address.addressLine1 || '',
      addressLine2: address.addressLine2 || '',
      city: address.city || '',
      stateProvince: address.stateProvince || '',
      country: address.country || 'United States',
      zipPostal: address.zipPostal || '',
      phone: address.phone || ''
    },
    onSubmit
  });

  return (
    <FormWrapper
      formik={formik}
      onCancel={cancelEdit}
      isLoading={loading}
      className="mt-8"
    >
      <FormField<OrganizationEditAddressType>
        name="addressLine1"
        type="text"
        label="Address 1"
        placeholder="Address 1"
      />
      <FormField<OrganizationEditAddressType>
        name="addressLine2"
        type="text"
        label="Address 2"
        placeholder="Address 2"
      />
      <FormField<OrganizationEditAddressType> name="city" type="text" />

      {formik.values.country !== 'United States' ? (
        <FormField<OrganizationEditAddressType>
          name="stateProvince"
          type="text"
        />
      ) : (
        <FormField<OrganizationEditAddressType>
          name="stateProvince"
          label="State"
          placeholder="State"
          type="select"
          options={states.map(s => ({
            label: s.name,
            value: s.value
          }))}
        />
      )}
      <FormField<OrganizationEditAddressType>
        name="country"
        type="select"
        options={countries.map(c => ({
          label: c.name,
          value: c.name
        }))}
        onChange={v => {
          if (v.value !== 'United States') {
            formik.setFieldValue('stateProvince', '');
          }
        }}
      />
      <FormField<OrganizationEditAddressType>
        name="zipPostal"
        label="Zip / Postal Code"
        type="text"
      />
      <FormField<OrganizationEditAddressType>
        name="phone"
        type="mask"
        mask="phoneNumber"
      />
    </FormWrapper>
  );
};

export default OrganizationEditAddressForm;
