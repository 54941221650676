import React from 'react';

interface OrganizationProps {
  children: React.ReactElement;
  path: string;
}
const Organization = (props: OrganizationProps) => {
  return <>{props.children}</>;
};

export default Organization;
