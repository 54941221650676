import React from 'react';

import { OptionProps } from 'react-select';
import { components } from 'react-select';

export type CustomDropdownOptionType = {
  label: string;
  value: string;
  description?: { id?: string; name?: string };
};

export const CustomDropdownOption = (
  props: OptionProps<CustomDropdownOptionType, false>
) => {
  const data = props.data as CustomDropdownOptionType;

  return (
    <components.Option {...props}>
      <div>
        <div>{data.label}</div>
        {data.description && (
          <div className="text-xs text-gray-600">{data.description.name}</div>
        )}
      </div>
    </components.Option>
  );
};
