import React, { useEffect } from 'react';
import ModalWrapper from '../../common/ModalWrapper';
import { FormField, FormWrapper } from '../../common/Form';
import { useFormik } from 'formik';
import EventApplicationHeader from './EventApplicationHeader';
import Button from '../../common/Button';
import { applyCouponToEventApplication } from '../api';
import { toast } from 'react-toastify';
import { CouponInfo } from '../CouponInfo';

interface Props {
  application: any;
  isOpen: boolean;
  onClose: () => void;
  proceedWithPayment: () => Promise<void>;
  isLoadingPaymentProceed: boolean;
  reloadApplication: () => Promise<void>;
}

export type EventApplicationCouponForm = {
  couponCode: string;
};

const EventApplicationCouponModal = ({
  application,
  isOpen,
  onClose,
  proceedWithPayment,
  isLoadingPaymentProceed,
  reloadApplication
}: Props) => {
  const [loadingApplyCoupon, setLoadingApplyCoupon] = React.useState(false);

  const formik = useFormik<EventApplicationCouponForm>({
    initialValues: {
      couponCode: application?.couponUse?.coupon?.code
    },
    onSubmit: async values => {
      const { couponCode: coupon } = values;
      if (!coupon) {
        toast.error('Coupon code is required');
        return;
      }
      setLoadingApplyCoupon(true);
      try {
        const result = await applyCouponToEventApplication({
          code: coupon,
          eventApplicationId: application.id
        });
        if (result?.state !== 'SUCCESS') {
          throw new Error(result?.message || 'Something went wrong');
        }
        await reloadApplication();
      } catch (err) {
        toast.error((err as any)?.message || 'Something went wrong');
      } finally {
        setLoadingApplyCoupon(false);
      }
    }
  });

  useEffect(() => {
    formik.setValues({
      couponCode: application?.couponUse?.coupon?.code || ''
    });
  }, [application?.id, application?.couponUse]);
  return (
    <ModalWrapper
      onClose={onClose}
      isOpen={isOpen}
      dialogClassName="sm:w-1/4 overflow-visible"
    >
      {application && (
        <FormWrapper
          formik={formik}
          isLoading={false}
          hideActionButtons={true}
          className="px-3 pb-4 rounded-xl"
        >
          <EventApplicationHeader title="Coupon code applying" size="xs" />
          {!application?.couponUse ? (
            <div className="flex gap-2 items-end mb-4">
              <FormField<EventApplicationCouponForm>
                name="couponCode"
                type="text"
              />
              <div>
                <Button
                  text="Apply"
                  type="submit"
                  isLoading={loadingApplyCoupon}
                  disabled={loadingApplyCoupon}
                />
              </div>
            </div>
          ) : (
            <>
              <CouponInfo
                couponUse={application?.couponUse}
                removeBtn={
                  <Button
                    className="flex gap-2"
                    type="button"
                    isLoading={loadingApplyCoupon}
                    onClick={async () => {
                      setLoadingApplyCoupon(true);
                      try {
                        await applyCouponToEventApplication({
                          code: '',
                          eventApplicationId: application.id
                        });
                        await reloadApplication();
                      } catch (err) {
                        toast.error(
                          (err as any)?.message || 'Something went wrong'
                        );
                      } finally {
                        setLoadingApplyCoupon(false);
                      }
                    }}
                    text="Remove"
                    color="error"
                    size="sm"
                  />
                }
              />
            </>
          )}

          <div className="flex mt-2">
            <div className="mr-2">
              <Button
                text="Procced with payment"
                type="button"
                onClick={proceedWithPayment}
                isLoading={isLoadingPaymentProceed}
                disabled={isLoadingPaymentProceed}
              />
            </div>
            <Button
              text="Cancel"
              type="button"
              color="cancel"
              onClick={onClose}
            />
          </div>
        </FormWrapper>
      )}
    </ModalWrapper>
  );
};

export default EventApplicationCouponModal;
