import React from 'react';

interface VideoCollectionProps {
  path: string;
  children: React.ReactNode;
}

const VideoCollection = (props: VideoCollectionProps) => {
  return <div className="-m-8">{props.children}</div>;
};

export default VideoCollection;
