import { navigate } from 'gatsby';
import React, { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { PackageContext } from '../../context/PackageContext';
import Button from '../common/Button';
import { updatePackageRequest } from './api';
import { FETCH_STATES, useFetch } from '../../hooks/useFetch';
import { PackageRequest } from './NewApiTypes.generated';

export const getNextRoute = (nextRoute: string) => {
  const { pathname } = window.location;
  const routeSplit = pathname.split('/');
  const upToLastSegment = routeSplit.slice(0, routeSplit.length - 1);
  const location = upToLastSegment.join('/') + `/${nextRoute}`;
  return location;
};

export const isEditablePackageRequest = (packageRequest: PackageRequest) => {
  return ['STARTED', 'CHANGES_REGUESTED', 'REOPENED'].some(
    status => status === packageRequest.status
  );
};

interface PackageSaveControls {
  previousRoute?: string;
  isDisabled?: boolean;
  packageRequest: any;
  segment: 'PD_SESSIONS' | 'EVENTS' | 'GROUPS' | 'INSTITUTES';
  segmentData: any;
  deleteData?: any;
  nextRoute: string;
}
const PackageSaveControls = (props: PackageSaveControls) => {
  const packageContext = useContext(PackageContext);

  const { apply: applyUpdatePackageRequest } = useFetch(updatePackageRequest);

  const [loading, setLoading] = useState('');

  const handleUpdatePackageRequest = async (
    data: any,
    goToNextRoute = false
  ) => {
    const res = await applyUpdatePackageRequest(data);

    if (res.state === 'SUCCESS') {
      toast.success('Package Request Updated');

      if (goToNextRoute) {
        navigate(getNextRoute(props.nextRoute));
      }
      packageContext.setHasUnsavedChanges(false);
      setLoading('');
      return;
    }

    toast.error(res.error || res.data?.message || 'Something went wrong');
  };

  return (
    <div className="my-auto flex flex-col items-end">
      <div className=" flex gap-x-3">
        {props.previousRoute && (
          <button
            onClick={() => {
              if (packageContext.hasUnsavedChanges) {
                if (
                  confirm(
                    'You have unsaved changes that will be lost. Are you sure you want to navigate away?'
                  )
                ) {
                  history.back();
                  packageContext.setHasUnsavedChanges(false);
                }
              } else {
                history.back();
                packageContext.setHasUnsavedChanges(false);
              }
            }}
            className="text-primary hover:underline text-lg mr-10 mb-4"
          >
            &larr; Previous
          </button>
        )}
        {/* {props.nextRoute && (
          <button
            onClick={() => {
              if (packageContext.hasUnsavedChanges) {
                if (
                  confirm(
                    'You have unsaved changes that will be lost. Are you sure you want to navigate away?'
                  )
                ) {
                  navigate(getNextRoute(props.nextRoute));
                  packageContext.setHasUnsavedChanges(false);
                }
              } else {
                navigate(getNextRoute(props.nextRoute));
                packageContext.setHasUnsavedChanges(false);
              }
            }}
            className="text-primary hover:underline text-lg mr-10 mb-4"
          >
            Next &rarr;
          </button>
        )} */}
      </div>
      {!!props.packageRequest &&
      (props.packageRequest as PackageRequest)?.status !== 'SUBMITTED' ? (
        <div className="my-auto flex">
          <div className="mr-4">
            <Button
              text="Save"
              onClick={() => {
                setLoading('save');
                handleUpdatePackageRequest({
                  packageRequestId: props.packageRequest.id,
                  segment: props.segment,
                  segmentData: props.segmentData,
                  deleteData: props.deleteData
                });
              }}
              isLoading={loading === 'save'}
              disabled={
                !isEditablePackageRequest(props.packageRequest) ||
                props.isDisabled ||
                loading === 'save-and-loading'
              }
            />
          </div>
          <div className="mr-4">
            <Button
              text="Save & Continue"
              onClick={() => {
                setLoading('save-and-loading');
                handleUpdatePackageRequest(
                  {
                    packageRequestId: props.packageRequest.id,
                    segment: props.segment,
                    segmentData: props.segmentData,
                    deleteData: props.deleteData
                  },
                  true
                );
              }}
              isLoading={loading === 'save-and-loading'}
              disabled={
                !isEditablePackageRequest(props.packageRequest) ||
                props.isDisabled ||
                loading === 'save'
              }
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PackageSaveControls;
