import React from 'react';
import Card from '../../common/Card';
import EventApplicationField from './EventApplicationField';

interface EventApplicationReviewSubmitProps {
  application: any;
}

const EventApplicationGeneralInformationPreview = (
  props: EventApplicationReviewSubmitProps
) => {
  const { application } = props;
  return (
    <Card className="shadow-none">
      <div className="w-1/2 mr-8">
        <div className="flex mb-4">
          <div className="w-1/2 mr-2">
            <EventApplicationField label="First Name">
              {application.firstName}
            </EventApplicationField>
          </div>
          <div className="w-1/2 ml-2">
            <EventApplicationField label="Last Name">
              {application.lastName}
            </EventApplicationField>
          </div>
        </div>
        <div className="mb-4">
          <EventApplicationField label="Address">
            {application.address1}
          </EventApplicationField>
        </div>
        <div className="mb-4">
          <EventApplicationField label="Address (con't)">
            {application.address2 || 'N/A'}
          </EventApplicationField>
        </div>
        <div className="flex mb-4">
          <div className="w-1/2 mr-1">
            <EventApplicationField label="City">
              {application.city}
            </EventApplicationField>
          </div>
          <div className="w-1/2 ml-1">
            <EventApplicationField label="State Province">
              {application.stateProvince}
            </EventApplicationField>
          </div>
        </div>
        <div className="flex mb-4">
          <div className="w-1/2 mr-1">
            <EventApplicationField label="Zip Postal">
              {application.zipPostal}
            </EventApplicationField>
          </div>
          <div className="w-1/2 ml-1">
            <EventApplicationField label="Country">
              {application.country}
            </EventApplicationField>
          </div>
        </div>
        <div>
          <EventApplicationField label="Phone">
            {application.phone}
          </EventApplicationField>
        </div>
      </div>
    </Card>
  );
};

export default EventApplicationGeneralInformationPreview;
