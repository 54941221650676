import { navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { getAffiliationsForUser } from '../members/api';
import { useFormik } from 'formik';
import { FormField, FormWrapper } from '../common/Form';
import { AuthContext } from '../../context/AuthContext';
import { useQuery } from 'react-query';
import { Organization } from '../members/NewApiTypes.generated';

const SubmitPurchaseOrderSchema = Yup.object().shape({
  file: Yup.string().required('File required'),
  filename: Yup.string().required('Filename required'),
  contactEmail: Yup.string()
    .email('Invalid email')
    .required('Email required'),
  contactName: Yup.string().required('Name required'),
  contactPhone: Yup.string()
    .test('validate-phone', 'Phone is invalid', (value: any) => {
      if (!value) return false;
      return value.indexOf('_') === -1;
    })
    .required('Phone is required'),
  number: Yup.string().required('Number required'),
  organization: Yup.string().required('Organization required')
});

export type PurchaseOrdersSubmitForm = {
  file: string;
  filename: string;
  contactEmail: string;
  contactName: string;
  contactPhone: string;
  number: string;
  organization: string;
};

type IForm = PurchaseOrdersSubmitForm;

interface PurchaseOrderFormProps {
  handleSubmit: any;
  onCancel: any;
  isLoading: boolean;
}

const PurchaseOrderForm = ({
  handleSubmit,
  onCancel,
  isLoading
}: PurchaseOrderFormProps) => {
  const auth = useContext(AuthContext);

  const [organizationOptions, setOrganizationOptions] = useState<any[]>([]);

  const { data } = useQuery<{
    organizations?: Organization[];
  }>('affiliations', () => getAffiliationsForUser());

  useEffect(() => {
    if (data?.organizations) {
      setOrganizationOptions(
        data.organizations.map(o => ({
          label: o.name,
          value: o.id
        }))
      );
    }
  }, [data]);

  const formik = useFormik<IForm>({
    validationSchema: SubmitPurchaseOrderSchema,
    initialValues: {
      file: '',
      filename: '',
      contactEmail: auth.authState?.email || '',
      contactName:
        auth.authState?.firstName && auth.authState?.lastName
          ? `${auth.authState?.firstName} ${auth.authState?.lastName}`
          : '',
      contactPhone: auth.authState?.phone || '',
      number: '',
      organization: ''
    },
    onSubmit: values =>
      handleSubmit({
        ...values,
        contactPhone: values.contactPhone.replace(/[^\d]/g, '')
      })
  });

  return (
    <>
      <FormWrapper formik={formik} isLoading={isLoading} onCancel={onCancel}>
        <div className="flex">
          <div className="w-1/2 mr-8">
            <div className="flex mb-2">
              <FormField<IForm>
                name="contactName"
                type="text"
                className="mr-2 w-1/2"
              />
              <FormField<IForm>
                name="contactEmail"
                type="text"
                className="w-1/2"
              />
            </div>
            <div className="flex  mb-2">
              <FormField<IForm>
                name="contactPhone"
                type="mask"
                mask="phoneNumber"
                className="w-1/2 mr-2"
              />
              <FormField<IForm>
                name="number"
                label="Purchase order number"
                placeholder="Purchase order number"
                type="text"
                className="w-1/2"
              />
            </div>
            <div>
              <FormField<IForm>
                name="organization"
                type="select"
                className="w-1/2"
                options={organizationOptions}
              />
              <div className="mt-1 sans-serif">
                <span
                  onClick={() => navigate('/members/profile')}
                  className="text-primary cursor-pointer sans-serif"
                >
                  Click here{' '}
                </span>{' '}
                to add an organization affiliation
              </div>
            </div>
          </div>
        </div>
        <FormField<IForm>
          name="file"
          type="file"
          subheading="Add a .pdf file"
          acceptedFileTypes={{
            'application/*': ['.pdf']
          }}
          filenamePath="filename"
        />
      </FormWrapper>
    </>
  );
};

export default PurchaseOrderForm;
