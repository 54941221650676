import { Link } from 'gatsby';
import React from 'react';
import Button from '../common/Button';

interface ManageRosterLinkProps {
  organizationId: string;
  withArrow: boolean;
}

const ManageRosterLink = (props: ManageRosterLinkProps) => {
  return (
    <Link to={`/members/organization/${props.organizationId}/school-roster`}>
      {props.withArrow ? (
        <Button text="&larr; Update School Roster" />
      ) : (
        <Button text="Update School Roster" />
      )}
    </Link>
  );
};

export default ManageRosterLink;
