import React from 'react';
import { FormWrapper, FormField } from '../../common/Form';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export type OrganizationEditNameType = {
  name: string;
};
const OrganizationEditNameSchema: Yup.SchemaOf<OrganizationEditNameType> =
  Yup.object().shape({
    name: Yup.string().required('Name is required')
  });
const OrganizationEditNameForm = ({
  cancelEdit,
  onSubmit,
  loading,
  name
}: {
  cancelEdit: any;
  name: string;
  loading: boolean;
  onSubmit: (values: OrganizationEditNameType) => Promise<void>;
}) => {
  const formik = useFormik<OrganizationEditNameType>({
    validationSchema: OrganizationEditNameSchema,
    initialValues: {
      name: name
    },
    onSubmit
  });

  return (
    <FormWrapper
      formik={formik}
      isLoading={loading}
      onCancel={cancelEdit}
      className="mt-8"
    >
      <FormField<OrganizationEditNameType> name="name" type="text" />
    </FormWrapper>
  );
};

export default OrganizationEditNameForm;
