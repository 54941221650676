import React from 'react';

interface EventApplicationFieldPros {
  label: string;
  children: any;
}

const EventApplicationField = (props: EventApplicationFieldPros) => {
  const { label, children } = props;
  return (
    <>
      <div className="sans-serif font-bold text-gray-600">{label}</div>
      <div className="sans-serif text-gray-500">{children}</div>
    </>
  );
};

export default EventApplicationField;
