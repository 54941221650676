import React from 'react';
import classnames from 'classnames';
import {
  faCheckCircle,
  faClock,
  faExclamationTriangle,
  faTimesCircle,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type PackageAndApplicationStatus =
  | 'NOT_STARTED'
  | 'STARTED'
  | 'SUBMITTED'
  | 'ACCEPTED'
  | 'REJECTED'
  | 'CHANGES_REQUESTED'
  | 'VOIDED'
  | 'WITHDRAWN';

function getStatusIcon(packageStatus: PackageAndApplicationStatus) {
  let icon;
  switch (packageStatus) {
    case 'STARTED':
      icon = faExclamationTriangle;
      break;
    case 'ACCEPTED':
      icon = faCheckCircle;
      break;
    case 'CHANGES_REQUESTED':
      icon = faTimesCircle;
      break;
    case 'REJECTED':
      icon = faTimesCircle;
      break;
    case 'VOIDED':
      icon = faTimesCircle;
      break;
    case 'SUBMITTED':
      icon = faClock;
      break;
  }
  return icon;
}

function getStatusText(packageStatus: PackageAndApplicationStatus) {
  let text;
  switch (packageStatus) {
    case 'STARTED':
      text = 'Application Started';
      break;
    case 'ACCEPTED':
      text = 'Application Accepted';
      break;
    case 'CHANGES_REQUESTED':
      text = 'Changes Requested';
      break;
    case 'REJECTED':
      text = 'Application Rejected';
      break;
    case 'VOIDED':
      text = 'Application Voided';
      break;
    case 'SUBMITTED':
      text = 'Application Submitted';
      break;
  }
  return text;
}

const ApplicationStatus = (props: { status: PackageAndApplicationStatus }) => {
  const statusClasses = classnames({
    'mt-2 sans-serif': true,
    'text-yellow-500':
      props.status === 'STARTED' || props.status === 'SUBMITTED',
    'text-red-500': props.status === 'REJECTED' || props.status === 'VOIDED',
    'text-green-600': props.status === 'ACCEPTED',
    'text-gray-600': props.status === 'STARTED'
  });
  const statusDescriptionClasses = classnames({
    'ml-2 sans-serif': true,
    'text-yellow-600':
      props.status === 'STARTED' || props.status === 'SUBMITTED',
    'text-red-600': props.status === 'REJECTED' || props.status === 'VOIDED',
    'text-green-600': props.status === 'ACCEPTED',
    'text-gray-600': props.status === 'STARTED'
  });
  return (
    <p className={statusClasses}>
      <FontAwesomeIcon icon={getStatusIcon(props.status) as IconDefinition} />
      <span className={statusDescriptionClasses}>
        {getStatusText(props.status)}
      </span>
    </p>
  );
};

export default ApplicationStatus;
