import {
  faExclamation,
  faExclamationTriangle,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface NoSelectionsWarningProps {}
const NoSelectionsWarning = (props: NoSelectionsWarningProps) => {
  return (
    <div className="border-yellow-500 border p-4 rounded-lg text-yellow-500 flex items-center justify-center">
      <FontAwesomeIcon icon={faExclamationTriangle} />
      <p className="text-gray-500 sans-serif ml-4">
        Your package does not include any items to select.
      </p>
    </div>
  );
};

export default NoSelectionsWarning;
