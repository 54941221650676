import { navigate } from 'gatsby';
import React from 'react';
import queryString from 'query-string';
import { FETCH_STATES, useFetch } from '../../hooks/useFetch';
import { toast } from 'react-toastify';
import { submitPurchaseOrderForInstitute } from '../members/api';
import SEO from '../Seo';
import Card from '../common/Card';
import ConferenceDaysSummary from './ConferenceDaysSummary';
import PurchaseOrderForm, {
  PurchaseOrdersSubmitForm
} from '../common/PurchaseOrderForm';

interface ConferenceDaysCheckoutPurchaseOrderProps {
  path: string;
}

const ConferenceDaysCheckoutPurchaseOrder = ({}: ConferenceDaysCheckoutPurchaseOrderProps) => {
  const { eventPaymentId } = queryString.parse(location.search);
  if (!eventPaymentId) {
    navigate(`/members/event-cart/checkout`);
  }

  const { apply, error: submitError, state } = useFetch(
    submitPurchaseOrderForInstitute
  );

  const handleSubmit = async (values: PurchaseOrdersSubmitForm) => {
    const res = await apply({
      ...values,
      eventPaymentId,
      type: 'CONFERENCE_DAY'
    });
    if (res.state === 'SUCCESS') {
      navigate(
        `/members/event-cart/checkout/success?eventPaymentId=${eventPaymentId}&source=purchase-order`
      );
    } else {
      toast.error(submitError);
    }
  };

  return (
    <>
      <SEO title="Purchase Orders Submit" />
      <Card className="mt-4">
        <ConferenceDaysSummary />
        <h3 className="text-lg mb-2">Submit Purchase Order</h3>
        <PurchaseOrderForm
          handleSubmit={handleSubmit}
          onCancel={() => navigate('/members/event-cart/checkout')}
          isLoading={state === FETCH_STATES.PROCESSING}
        />
      </Card>
    </>
  );
};

export default ConferenceDaysCheckoutPurchaseOrder;
