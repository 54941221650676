import React from 'react';
import Select, { components } from 'react-select';

interface OptionProps {
  isSelected: boolean;
  data: { value: string; label: string; email: string };
}
const Option = (props: OptionProps) => {
  return (
    <div>
      <components.Option {...props}>
        <div className="flex">
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
            className="my-auto"
          />
          <div className="ml-2">
            <p>{props.data.label}</p>
            <p className="text-sm text-gray-400">{props.data.email}</p>
          </div>
        </div>
      </components.Option>
    </div>
  );
};

interface CheckedSelectProps {
  options: { value: string; label: string }[];
  onChange: any;
  formatOptionLabel: any;
  defaultValue: any;
  name?: string;
}
const CheckedSelect = (props: CheckedSelectProps) => {
  return (
    <Select
      {...props}
      options={props.options}
      defaultValue={props.defaultValue}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      onChange={props.onChange}
      components={{ Option }}
    />
  );
};

export default CheckedSelect;
