import _ from 'lodash';
import { useState } from 'react';

export enum FETCH_STATES {
  IDLE = 'idle',
  PROCESSING = 'processing',
  LOADED = 'loaded',
  ERROR = 'error'
}

export const useFetch = (
  promise: (
    ...values: any
  ) => Promise<{ state: 'SUCCESS' | 'ERROR' | 'UNAUTHORIZED' } & any>
) => {
  const [state, setState] = useState<FETCH_STATES>(FETCH_STATES.IDLE);
  const [error, setError] = useState(undefined);

  const apply = async (
    ...values: any
  ): Promise<{
    state: 'SUCCESS' | 'ERROR';
    error?: string | undefined | unknown;
    data?: any;
  }> => {
    setError(undefined);
    setState(FETCH_STATES.PROCESSING);
    try {
      const result = await promise(...values);

      if (result.state === 'ERROR' || result.state === 'UNAUTHORIZED') {
        setState(FETCH_STATES.ERROR);
        setError(result.message);
      } else {
        setState(FETCH_STATES.LOADED);
      }

      return { data: _.omit(result, 'state'), state: result.state };
    } catch (error) {
      setState(FETCH_STATES.ERROR);
      setError((error as any)?.message);

      return { error, state: 'ERROR' };
    }
  };

  return { state, apply, error };
};
