import React, { useEffect } from 'react';
import ModalWrapper from '../../common/ModalWrapper';
import { FormField, FormWrapper } from '../../common/Form';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import EventApplicationHeader from './EventApplicationHeader';
import { OptionType } from '../../../constants/select';
import Button from '../../common/Button';
import { useMutation } from 'react-query';
import { updateEventApplicationPaymentMethod } from '../api';
import { toast } from 'react-toastify';

interface Props {
  application: any;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export type ReviewAndSubmitForm = {
  paymentOption: string;
};

// @ts-ignore
const EventApplicationEventPaymentValidationSchema: Yup.SchemaOf<ReviewAndSubmitForm> = Yup.object().shape(
  {
    paymentOption: Yup.string().required('Payment option is required')
  }
);

const EventApplicationEventPaymentModal = ({
  application,
  isOpen,
  onClose,
  onSubmit
}: Props) => {
  const [loading, setLoading] = React.useState(false);
  const paymentOptions = application?.event?.finance?.paymentOptions || [];
  const options: OptionType[] = paymentOptions.map((o: any) => ({
    value: o.id,
    label: o.label
  }));

  const mutation = useMutation(
    ['updateEventApplicationPaymentMethod'],
    updateEventApplicationPaymentMethod
  );

  const formik = useFormik<ReviewAndSubmitForm>({
    validationSchema: EventApplicationEventPaymentValidationSchema,
    initialValues: {
      paymentOption: application?.paymentOption?.id
        ? ((options.find(
            (o: any) => o.value === application?.paymentOption?.id
          ) as OptionType).value as string)
        : ''
    },
    onSubmit: async () => {
      setLoading(true);
      await mutation.mutate(
        {
          applicationId: application.id,
          paymentOption: formik.values.paymentOption
        },
        {
          onSuccess: data => {
            setLoading(false);
            if (data.state === 'BAD_REQUEST') {
              toast.error(data.message || 'Something went wrong');
            } else if (data.state === 'SUCCESS') {
              toast.success('Payment method updated successfully');
              onSubmit();
            }
          }
        }
      );
    }
  });
  useEffect(() => {
    formik.setValues({
      paymentOption: application?.paymentOption?.id
        ? ((options.find(
            (o: any) => o.value === application?.paymentOption?.id
          ) as OptionType).value as string)
        : ''
    });
  }, [
    application?.paymentOption?.id,
    application?.event?.finance?.paymentOptions
  ]);
  return (
    <ModalWrapper
      onClose={onClose}
      isOpen={isOpen}
      dialogClassName="sm:w-1/4 overflow-visible"
    >
      {application && (
        <FormWrapper
          formik={formik}
          isLoading={false}
          hideActionButtons={true}
          className="px-3 pb-4 rounded-xl"
        >
          <EventApplicationHeader title="Change payment method" size="xs" />
          <FormField
            label="Payment method"
            placeholder="Payment method"
            name="paymentOption"
            type="select"
            options={options}
            isLoading={false}
          />
          <div className="flex mt-2">
            <div className="mr-2">
              <Button
                text="Save"
                type="submit"
                disabled={!formik.isValid}
                isLoading={loading}
              />
            </div>
            <Button
              text="Cancel"
              type="button"
              color="cancel"
              onClick={onClose}
            />
          </div>
        </FormWrapper>
      )}
    </ModalWrapper>
  );
};

export default EventApplicationEventPaymentModal;
