import { Disclosure } from '@headlessui/react';
import { format } from 'date-fns';
import * as _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { User } from '../members/NewApiTypes.generated';
import NoSelectionsWarning from '../members/NoSelectionsWarning';
import SelectionInstructions from '../members/SelectionInstructions';
import {
  EventSectionTicketAssignment,
  TicketAssignments,
  UserOptionType
} from './ConferenceDaysAssignTickets';
import EventTicketPanel from '../event-sections/EventTicketPanel';
import {
  getTicketAllocationNotAvailableMessage,
  isNotAvailableForPurchase
} from '../../helpers/eventSections.helper';
import { getUserInfo } from '../../util/auth';
import { formatDates } from '../../util/date';

interface AllocateConferenceDaysTicketsProps {
  organizationId: string;
  ticketAssignments: TicketAssignments;
  packageRequestOrganizationUsers: (User & { isPending?: boolean })[];
  tickets: EventSectionTicketAssignment[];
  onUpdateEventSections: (a: EventSectionTicketAssignment) => void;
  newUserBtn: JSX.Element;
}
const AllocateConferenceDaysTickets = (
  props: AllocateConferenceDaysTicketsProps
) => {
  const { ticketAssignments } = props;
  const sectionsByEvent = _.groupBy(
    ticketAssignments.sections,
    s => s.event.id
  );
  const [user, setUser] = useState({});
  useEffect(() => {
    const fetchUser = async () => {
      setUser(await getUserInfo());
    };
    fetchUser();
  }, []);
  return (
    <>
      {props.newUserBtn}
      {!ticketAssignments?.sections?.length ? (
        <div className="w-1/2 m-auto my-6">
          <NoSelectionsWarning />
        </div>
      ) : (
        <div className="w-1/2 m-auto my-6">
          <SelectionInstructions />
        </div>
      )}
      {Object.keys(sectionsByEvent).map(eventId => {
        const sections = sectionsByEvent[eventId];
        return (
          <div key={eventId}>
            <h3 className="text-xl my-2">{sections[0].event.name}</h3>
            {sections.map((section, i) => {
              return (
                <div className="mb-4 flex flex-col gap-2" key={i}>
                  <Disclosure key={i}>
                    {({ open }) => {
                      const assignmentsCount = section.numberOfTickets;
                      const existingTickets = props.tickets.find(
                        t => t.sectionId === section.id
                      ) || {
                        sectionId: section.id,
                        assignedUsers: Array(assignmentsCount).fill(null)
                      };
                      const usersOptions =
                        props.packageRequestOrganizationUsers?.map(sd => ({
                          value: sd?.id || '',
                          label:
                            [
                              sd?.firstName,
                              sd?.lastName,
                              sd?.isPending && '(Pending)'
                            ]
                              .filter(Boolean)
                              .join(' ') || '',
                          email: sd?.email || ''
                        })) || [];

                      const spotsLack =
                        assignmentsCount - existingTickets.assignedUsers.length;

                      if (spotsLack > 0) {
                        existingTickets.assignedUsers.concat(
                          Array(spotsLack).fill(null)
                        );
                      }

                      return (
                        <EventTicketPanel
                          open={open}
                          title={section.eventSection.title || ''}
                          ticketTitle={section.eventSection.title || ''}
                          ticketPrice={section.eventTicketType.price}
                          dates={formatDates(
                            section.eventSection.dates as string[]
                          )}
                          actualAmount={assignmentsCount}
                          users={usersOptions}
                          blockApplicantAllocationMessage={
                            isNotAvailableForPurchase({
                              eventSection: section.eventSection,
                              event:
                                section?.eventSection?.group?.eventPath?.event,
                              type: 'CONFERENCE_DAY',
                              isImpersonated: (user as any)?.impersonator
                            })
                              ? getTicketAllocationNotAvailableMessage(
                                  section?.eventSection?.group?.eventPath?.event
                                    .daysBeforePurchaseClose
                                )
                              : undefined
                          }
                          assignments={existingTickets}
                          onAssignmentSelect={userSelect => {
                            const assignedUsers = [
                              ...existingTickets.assignedUsers
                            ];

                            assignedUsers[userSelect.position] =
                              usersOptions.find(
                                u => u.value === userSelect.userId
                              ) || null;

                            const updatedOrgPackageEvent: EventSectionTicketAssignment = {
                              ...existingTickets,
                              assignedUsers
                            };

                            props.onUpdateEventSections(updatedOrgPackageEvent);
                          }}
                          onClearAssignment={e => {
                            const assignedUsers: (UserOptionType | null)[] = [
                              ...existingTickets.assignedUsers
                            ];

                            if (assignedUsers[e.position]?.value === e.userId) {
                              assignedUsers[e.position] = null;
                            }

                            const updatedOrgPackageEvent: EventSectionTicketAssignment = {
                              ...existingTickets,
                              assignedUsers: assignedUsers as any[]
                            };

                            props.onUpdateEventSections(updatedOrgPackageEvent);
                          }}
                        />
                      );
                    }}
                  </Disclosure>
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

export default AllocateConferenceDaysTickets;
