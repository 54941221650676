import React from 'react';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import { useQuery } from 'react-query';
import Card from '../common/Card';
import LoadingDialog from '../common/LoadingDialog';
import SEO from '../Seo';
import { getEventForAttendance } from './Queries';

interface AttendanceProps {
  path: string;
  eventId: string;
}
const Attendance = (props: AttendanceProps) => {
  const { isFetching, error, data } = useQuery('eventWithSections', () =>
    getEventForAttendance(Number(props.eventId))
  );

  let sections = [];
  var getFlattenedSections = function(section: any, isAdvanced?: boolean) {
    if (section.Sections) {
      for (var i = 0; i < section.Sections.length; i++) {
        getFlattenedSections(
          section.Sections[i],
          section.IsAdvancedFlag || isAdvanced
        );
      }
    }
    if (section.AssignedSections && section.AssignedSections.length) {
      section.IsAdvanced = isAdvanced;
      if (section.SectionID && section.SectionID != null)
        sections.push(section);
    }
  };

  if (data) {
    getFlattenedSections(data);
    // setSections(tempSections);
  }

  return (
    <Card>
      <SEO title={data ? `${data.ShortName} Attendance` : `Attendance`} />
      {isFetching && <LoadingDialog />}
      {!isFetching && (
        <>
          <h2 className="sans-serif text-primary font-bold text-2xl border-b border-gray-300 mb-4">
            {data.Name}
          </h2>
          {data && !sections.length && (
            <p className="sans-serif">No sections found</p>
          )}
          {data && !!sections.length && (
            <>
              <div className="my-6">
                <p className="text-gray-700 text-lg sans-serif">
                  Please select your section to mark attendance.
                </p>
              </div>
              <ul>
                {sections.map((section: any, i: number) => (
                  <li className="mb-2 py-1 border-gray-200 border-b" key={i}>
                    <p className="text-lg text-primary hover:text-primary-dark leading-8">
                      <FontAwesomeIcon
                        icon={faExternalLinkAlt}
                        className="mr-2"
                        style={{ fontSize: '14px' }}
                      />
                      {section.IsAdvanced ? (
                        <span className="bg-primary rounded-full text-blue-100 py-1 px-3 sans-serif text-xs mx-2">
                          Advanced
                        </span>
                      ) : (
                        <span className="rounded-full border border-primary text-primary py-1 px-3 sans-serif text-xs mx-2">
                          First Year
                        </span>
                      )}
                      <Link
                        to={`/members/events/${section.EventID}/attendance/${
                          section.SectionID
                        }?advanced=${section.IsAdvanced ? 'true' : 'false'}`}
                      >
                        <span className="sans-serif hover:underline">
                          {section.AdminTitle}
                        </span>
                      </Link>
                    </p>
                  </li>
                ))}
              </ul>
            </>
          )}
        </>
      )}
    </Card>
  );
};

export default Attendance;
