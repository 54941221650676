import React from 'react';
import { FormWrapper, FormField } from '../../common/Form';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IOrganization } from '../NewApiTypes';

export type OrganizationEditTicketRecipientType = {
  ticketRecipient: string;
};
const OrganizationEditTicketRecipientSchema: Yup.SchemaOf<OrganizationEditTicketRecipientType> =
  Yup.object().shape({
    ticketRecipient: Yup.string().required('Ticket recipient is required')
  });
const OrganizationEditTicketRecipientForm = ({
  cancelEdit,
  onSubmit,
  loading,
  organization
}: {
  cancelEdit: any;
  organization: IOrganization;
  loading: boolean;
  onSubmit: (values: OrganizationEditTicketRecipientType) => Promise<void>;
}) => {
  const formik = useFormik<OrganizationEditTicketRecipientType>({
    validationSchema: OrganizationEditTicketRecipientSchema,
    initialValues: {
      ticketRecipient: organization?.ticketRecipient?.id || ''
    },
    onSubmit
  });

  return (
    <FormWrapper
      formik={formik}
      isLoading={loading}
      onCancel={cancelEdit}
      className="mt-8"
    >
      <FormField<OrganizationEditTicketRecipientType>
        name="ticketRecipient"
        type="select"
        options={
          organization?.memberships?.map(m => ({
            label: m.user?.firstName + ' ' + m.user?.lastName,
            value: m.id
          })) || []
        }
      />
    </FormWrapper>
  );
};

export default OrganizationEditTicketRecipientForm;
