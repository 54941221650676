import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { countries } from '../../data/countries';
import { FormField, FormWrapper } from '../common/Form';
import moment from 'moment';
import { OptionType } from '../../constants/select';
import {
  CustomDropdownOption,
  CustomDropdownOptionType
} from '../common/CustomDropdownOption';
import { Organization } from './NewApiTypes.generated';
import { getOrganizations } from './api';
import { debounce } from 'lodash';
import { SEARCH_DEBOUNCE_TIME } from '../../constants/input';
import classNames from 'classnames';
import { navigate } from 'gatsby';

const NewStaffMemberSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Email is required'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  zipPostal: Yup.string()
    .max(10)
    .nullable(),
  country: Yup.string().required('Country is required'),
  grades: Yup.array().required('Grade selection is required'),
  position: Yup.string().required('Position is required'),
  toolkitAccess: Yup.boolean(),
  dateOfBirth: Yup.string()
    .test('validate-date', 'Date is invalid', (value: any) => {
      if (!value) return true;
      return moment(value, 'MM/DD/YYYY').isValid();
    })
    .nullable(),
  ssnLastFour: Yup.number()
    .min(1000)
    .max(9999)
    .nullable()
});

type NewStaffMemberProps = {
  positions: any[];
  grades: any[];
  onCancel: () => void;
  onSubmit: (userData: any) => void;
  isSubmitting: boolean;
} & (
  | {
      organizationId: string;
      isForSimplifiedRegistration?: false | null | undefined;
    }
  | { organizationId?: undefined | null; isForSimplifiedRegistration: true }
);

export type NewStaffMemberForm = {
  email: string;
  firstName: string;
  lastName: string;
  zipPostal?: string;
  country: string;
  position: string;
  grades: string;
  toolkitAccess?: boolean;
  dateOfBirth?: string;
  ssnLastFour?: string;
  organization?: OptionType;
};

type IForm = NewStaffMemberForm;

const NewStaffMember = (props: NewStaffMemberProps) => {
  const [isOrganizationLoading, setOrganizationLoading] = useState(false);

  const formik = useFormik<IForm>({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      zipPostal: '',
      country: 'United States',
      position: '',
      grades: '',
      toolkitAccess: false,
      dateOfBirth: '',
      ssnLastFour: ''
    },
    validationSchema: NewStaffMemberSchema,
    onSubmit: (values: any) => {
      props.onSubmit(values);
    }
  });

  const loadOrganizationOptions = async (
    inputValue: string,
    callback: (options: Array<CustomDropdownOptionType>) => void
  ) => {
    setOrganizationLoading(true);
    const result = await getOrganizations({ search: inputValue });
    const organizations = result.organizations as Organization[];
    setOrganizationLoading(false);
    callback(
      organizations.map(o => ({
        value: o.id,
        label: o.name,
        description: o.district
      }))
    );
  };

  const Wrapper = props.isForSimplifiedRegistration ? 'aside' : 'section';

  return (
    <>
      {props.isForSimplifiedRegistration && (
        <div
          className="h-screen w-0  sm:w-1/4 md:w-1/2 lg:w-2/3 fixed bg-black opacity-30 z-10 top-0 bottom-0 left-0 overflow-hidden"
          onClick={props.onCancel}
        />
      )}
      <Wrapper
        className={classNames({
          'p-4 border-gray-300 border rounded-lg': !props.isForSimplifiedRegistration,

          'w-full sm:w-3/4 md:w-1/2 lg:w-1/3 shadow-2xl fixed top-0 bottom-0 right-0 bg-white pt-4 overflow-scroll z-10':
            props.isForSimplifiedRegistration
        })}
      >
        {!props.isForSimplifiedRegistration && (
          <p className="text-primary sans-serif font-bold text-lg mb-4">
            Add a staff member
          </p>
        )}

        <FormWrapper
          formik={formik}
          isLoading={props.isSubmitting}
          onCancel={() => {
            formik.resetForm();
            props.onCancel();
          }}
          className={classNames({
            'px-8 py-4': props.isForSimplifiedRegistration
          })}
        >
          {props.isForSimplifiedRegistration && (
            <>
              <FormField<IForm>
                name="organization"
                type="async-select"
                className={classNames({
                  'w-96 mb-2 pr-4': !props.isForSimplifiedRegistration
                })}
                loadOptions={debounce(
                  loadOrganizationOptions,
                  SEARCH_DEBOUNCE_TIME
                )}
                onChange={data =>
                  formik.setFieldValue('organization', data, true)
                }
                isLoading={isOrganizationLoading}
                components={{ Option: CustomDropdownOption as any }}
              />
              <div className="-mt-1 sans-serif text-xs mb-0.5">
                <span
                  onClick={() => navigate('/members/profile')}
                  className="text-primary cursor-pointer sans-serif"
                >
                  Click here{' '}
                </span>{' '}
                to add an organization affiliation
              </div>
            </>
          )}
          <FormField<IForm>
            name="email"
            type="email"
            autoFocus={!props.isForSimplifiedRegistration}
            className={classNames({
              'w-96 mb-2 pr-4': !props.isForSimplifiedRegistration
            })}
          />
          <div
            className={classNames({
              'flex gap-x-2 ': true,
              'w-1/2 mt-6 pb-8 border-b border-gray-200': !props.isForSimplifiedRegistration,
              'w-full': props.isForSimplifiedRegistration
            })}
          >
            <FormField<IForm>
              name="firstName"
              type="text"
              className={classNames({
                'w-44': !props.isForSimplifiedRegistration,
                'w-full': props.isForSimplifiedRegistration
              })}
            />
            <FormField<IForm>
              name="lastName"
              type="text"
              className={classNames({
                'w-44': !props.isForSimplifiedRegistration,
                'w-full': props.isForSimplifiedRegistration
              })}
            />
          </div>
          <div
            className={classNames({
              'flex gap-x-2 ': true,
              'w-1/2 mt-6 pb-8 border-b border-gray-200': !props.isForSimplifiedRegistration,
              'w-full': props.isForSimplifiedRegistration
            })}
          >
            <FormField<IForm>
              name="zipPostal"
              label="Zip / Postal Code"
              type="text"
              className={classNames({
                'w-44': !props.isForSimplifiedRegistration,
                'w-full': props.isForSimplifiedRegistration
              })}
            />
            <FormField<IForm>
              name="country"
              type="select"
              className={classNames({
                'w-44': !props.isForSimplifiedRegistration,
                'w-full': props.isForSimplifiedRegistration
              })}
              options={countries.map(c => ({
                label: c.name,
                value: c.name
              }))}
            />
          </div>

          <div
            className={classNames({
              'w-1/2 mt-6 pb-8 border-b border-gray-200': !props.isForSimplifiedRegistration,
              'w-full': props.isForSimplifiedRegistration
            })}
          >
            <div className="flex mb-2 gap-x-2">
              <FormField<IForm>
                name="position"
                type="select"
                className={classNames({
                  'w-44': !props.isForSimplifiedRegistration,
                  'w-full': props.isForSimplifiedRegistration
                })}
                options={props.positions.map(p => ({
                  label: p.label,
                  value: p.name
                }))}
              />
              <FormField<IForm>
                name="grades"
                type="select"
                className={classNames({
                  'w-44': !props.isForSimplifiedRegistration,
                  'w-full': props.isForSimplifiedRegistration
                })}
                isMulti
                options={props.grades.map(g => ({
                  label: g.name,
                  value: g.id
                }))}
              />
            </div>
            <FormField<IForm>
              name="toolkitAccess"
              label="Allow Toolkit Access"
              type="checkbox"
              className="mt-4"
            />
          </div>
          {!props.isForSimplifiedRegistration && (
            <div className="mt-6 mb-4 w-1/2">
              <p className="text-sm text-gray-700 sans-serif">
                If you are a teacher in New York state and wish to receive CTLE
                (Continuing Teacher and Leadership Education) credit for any
                events please provide your DOB and the last four numbers of your
                SSN.
              </p>
              <div className="mt-4 flex mb-4 gap-x-2">
                <FormField<IForm>
                  name="dateOfBirth"
                  type="mask"
                  mask="dateOfBirth"
                  placeholder="MM/DD/YYYY"
                />

                <FormField<IForm>
                  name="ssnLastFour"
                  label="Last 4 of SSN"
                  type="number"
                />
              </div>
            </div>
          )}
        </FormWrapper>
      </Wrapper>
    </>
  );
};

export default NewStaffMember;
