import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import uniqBy from 'lodash.uniqby';
import React, { useState, useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import Select, { ValueType } from 'react-select';
import Card from '../common/Card';
import LoadingDialog from '../common/LoadingDialog';
import SEO from '../Seo';
import { getOrganizations } from './api';
import ManageOrganization from './ManageOrganization';
import { getCurrentOrganizationUsers } from './Queries';
import SelectService from './SelectService';
import { AuthContext } from '../../context/AuthContext';
interface OrganizationSelect {
  path: string;
  children: React.ReactChildren;
}
const OrganizationSelect = (props: OrganizationSelect) => {
  const location = useLocation();
  const auth = useContext(AuthContext);

  const splitPath = location.pathname.split('/');
  const currentRoute = splitPath[2];

  const [selectedOrganization, setSelectedOrganization] = useState();
  const { data, isLoading } = useQuery('organizations', getOrganizations);
  const [adminOrOwnerOrgs, setAdminOrOwnerOrgs] = useState();

  useEffect(() => {
    setAdminOrOwnerOrgs(
      data?.organizations?.filter(org =>
        org?.memberships?.some(
          member =>
            member.userId === auth.authState.id &&
            !member.archivedAt &&
            (member.role === 'OWNER' || member.role === 'ADMIN')
        )
      )
    );
  }, [data, setAdminOrOwnerOrgs, auth]);

  return (
    <>
      <SEO title="Organization Selection" />
      <Card>
        <h2 className="text-primary font-bold text-2xl">
          Select an Organization
        </h2>
        {isLoading ? <LoadingDialog /> : null}
        {data && data.organizations ? (
          <div className="mt-4">
            {currentRoute === 'manage-services' ? (
              <p className="sans-serif text-lg mb-2">
                Select the organization you would like to manage services for
              </p>
            ) : currentRoute === 'organization' ? (
              <p className="sans-serif text-lg mb-2">
                Select the organization you would like to manage
              </p>
            ) : null}
            <Select
              className="w-1/2"
              options={adminOrOwnerOrgs?.map(org => ({
                value: org.id,
                label: org.name
              }))}
              onChange={({ value }) => {
                setSelectedOrganization(value);
              }}
            />
          </div>
        ) : null}
      </Card>
      {selectedOrganization && currentRoute === 'manage-services' && (
        <div className="mt-4">
          <SelectService organizationId={String(selectedOrganization)} />
        </div>
      )}
      {selectedOrganization &&
        currentRoute === 'organization' &&
        navigate(`/members/organization/${String(selectedOrganization)}`)}
    </>
  );
};

export default OrganizationSelect;
