import { useFormik } from 'formik';
import React from 'react';
import Card from '../common/Card';
import { FormField, FormWrapper } from '../common/Form';
import * as Yup from 'yup';

type ExampleForm = {
  name: string;
  email: string;
  message: string;
  number: number;
  dateOfBirth: string;
  phoneNumber: string;
  select: string;
  multiSelect: string[];
  file: string;
  filename: string;
};

type IForm = ExampleForm;

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name required'),
  email: Yup.string().email('Invalid email').required('Email required'),
  message: Yup.string(),
  number: Yup.number().min(1),
  dateOfBirth: Yup.string(),
  phoneNumber: Yup.string(),
  select: Yup.string(),
  multiSelect: Yup.array().of(Yup.string())
});

const options = ['1', '2', '3', '4', '5', '6', '7'].map(option => ({
  value: option,
  label: option
}));

const FormExample = ({ path }: { path: string }) => {
  const getDefaultFormValues = () => {
    return {
      name: '',
      email: '',
      message: '',
      number: 0,
      dateOfBirth: '',
      phoneNumber: '',
      select: '',
      multiSelect: [],
      file: '',
      filename: ''
    };
  };

  const formik = useFormik<IForm>({
    initialValues: getDefaultFormValues(),
    onSubmit: values => {
      console.log(values);
    },
    validationSchema
  });

  const handleReset = () => {
    formik.resetForm();
  };

  return (
    <div>
      <Card>
        <FormWrapper formik={formik} onCancel={handleReset}>
          <FormField<IForm> name="name" type="text" />
          <FormField<IForm> name="email" type="email" />
          <FormField<IForm> name="message" type="textarea" />
          <FormField<IForm> name="number" type="number" />
          <FormField<IForm> name="dateOfBirth" type="mask" mask="dateOfBirth" />
          <FormField<IForm> name="phoneNumber" type="mask" mask="phoneNumber" />
          <FormField<IForm> name="select" type="select" options={options} />
          <FormField<IForm>
            name="multiSelect"
            type="select"
            options={options}
            isMulti
          />
          <FormField<IForm> name="file" type="file" filenamePath="filename" />
        </FormWrapper>
      </Card>
    </div>
  );
};

export default FormExample;
