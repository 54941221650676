import React, { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import { navigate } from 'gatsby';
import MembersPageTitle from '../MembersPageTitle';
import Card from '../../common/Card';
import Button from '../../common/Button';
import { getEventRegistrationPayment } from '../api';

const EventRegistrationRequestCheckoutSuccess = (props: any) => {
  const {
    eventRegistrationRequestId,
    eventRegistrationPaymentId,
    source
  } = queryString.parse(location.search);
  const allUrlArgs = queryString.parse(location.search);
  if (!eventRegistrationRequestId || !eventRegistrationPaymentId) {
    navigate(`/members/registration/requests`);
  }

  const [paymentInProgress, setPaymentInProgress] = useState(true);
  const intervalRef: { current: NodeJS.Timeout | null } = useRef(null);

  const loadEventPayment = async (intervalRef: any) => {
    const result = await getEventRegistrationPayment(
      eventRegistrationPaymentId as string
    );
    if (result.state === 'ERROR') {
      navigate(`/members/event-cart/checkout`);
    } else if (result?.eventRegistrationPayment?.status === 'PAID') {
      clearInterval(intervalRef.current as NodeJS.Timeout);
      setPaymentInProgress(false);
    }
  };

  useEffect(() => {
    intervalRef.current = setInterval(async () => {
      loadEventPayment(intervalRef);
    }, 2000);
    loadEventPayment(intervalRef);

    return () => {
      clearInterval(intervalRef.current as NodeJS.Timeout);
    };
  }, []);

  return (
    <>
      <MembersPageTitle title="Event Registration Request Payment" />
      <Card className="mt-4 flex flex-col items-center">
        {paymentInProgress ? (
          <>
            <div className="p-4 text-primary flex justify-center italic">
              Your payment is processing, please wait...
            </div>
          </>
        ) : (
          <>
            <div className="p-4 text-primary flex justify-center">
              Your purchase has been successfully processed.
            </div>
            <Button
              text="Back to Registration Request"
              onClick={() =>
                navigate(
                  `/members/events/registration/requests/${eventRegistrationRequestId}`
                )
              }
            />
          </>
        )}
      </Card>
    </>
  );
};

export default EventRegistrationRequestCheckoutSuccess;
