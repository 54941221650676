import React, { ReactNode } from 'react';

interface EventSectionsProps {
  path: string;
  children: ReactNode;
}
const EventSections = (props: EventSectionsProps) => {
  return (
    <div>
      <>{props.children}</>
    </div>
  );
};

export default EventSections;
