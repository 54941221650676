import React, { useContext, useEffect, useState } from 'react';
import Card from '../common/Card';
import SEO from '../Seo';
import { useQuery } from 'react-query';
import {
  getAffiliationsForUser,
  getContractsByOrgnizationId,
  getEventApplications,
  submitPurchaseOrderForInstitute
} from './api';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { Link, navigate } from 'gatsby';
import * as Yup from 'yup';
import { FETCH_STATES, useFetch } from '../../hooks/useFetch';
import { FormField, FormWrapper } from '../common/Form';
import { Contract, Organization } from './NewApiTypes.generated';
import { AuthContext } from '../../context/AuthContext';

const PackagePurchaseOrderSchema = Yup.object().shape({
  contract: Yup.string().required('Package required'),
  file: Yup.string().required('File required'),
  filename: Yup.string().required('Filename required'),
  contactEmail: Yup.string()
    .email('Invalid email')
    .required('Email required'),
  contactName: Yup.string().required('Name required'),
  contactPhone: Yup.string()
    .test('validate-phone', 'Phone is invalid', (value: any) => {
      if (!value) return false;
      return value.indexOf('_') === -1;
    })
    .required('Phone is required'),
  number: Yup.string().required('Number required'),
  total: Yup.number().required('Total required'),
  organization: Yup.string().required('Organization required')
});

interface PurchaseOrdersPackageProps {
  path: string;
}

type PurchaseOrdersPackageForm = {
  organization: string;
  contract: string;
  file: string;
  filename: string;
  contactEmail: string;
  contactName: string;
  contactPhone: string;
  number: string;
  total: number;
};

type IForm = PurchaseOrdersPackageForm;

const PurchaseOrdersPackage = (props: PurchaseOrdersPackageProps) => {
  const auth = useContext(AuthContext);

  const [organizationOptions, setOrganizationOptions] = useState<any[]>([]);
  const [contractOptions, setContractOptions] = useState<any[]>([]);
  const [contractKey, setContractKey] = useState(new Date().getTime());

  const { apply, error: submitError, state } = useFetch(
    submitPurchaseOrderForInstitute
  );

  const handleSubmit = async (values: any) => {
    const res = await apply({
      ...values,
      total: (values.total || 0) * 100,
      type: 'PACKAGE'
    });
    if (res.state === 'SUCCESS') {
      toast.success('Purchase order submitted successfully');
      navigate('/members/purchase-orders');
    } else {
      toast.error(submitError);
    }
  };

  const formik = useFormik<IForm>({
    validationSchema: PackagePurchaseOrderSchema,
    initialValues: {
      organization: '',
      contract: '',
      file: '',
      filename: '',
      contactEmail: auth.authState?.email || '',
      contactName:
        auth.authState?.firstName && auth.authState?.lastName
          ? `${auth.authState?.firstName} ${auth.authState?.lastName}`
          : '',
      contactPhone: auth.authState?.phone || '',
      number: '',
      total: 0
    },
    onSubmit: handleSubmit
  });

  const {
    data: organizationsData,
    isLoading: isLoadingOrganization
  } = useQuery<{
    organizations?: Organization[];
  }>('affiliations', () => getAffiliationsForUser());

  const {
    data: contractsData,
    isLoading: isLoadingContracts,
    error
  } = useQuery<{
    contracts?: any[];
  }>(
    ['contractsByOrgIdQuery', formik.values.organization],
    () => getContractsByOrgnizationId(formik.values.organization),
    {
      enabled: !!formik.values.organization
    }
  );

  useEffect(() => {
    if (organizationsData?.organizations) {
      setOrganizationOptions(
        organizationsData?.organizationMemberships
          .filter(m => m.role === 'ADMIN' || m.role === 'OWNER')
          .map(m => m.organization)
          .map(o => ({
            label: o.name,
            value: o.id
          }))
      );
    }
  }, [organizationsData]);

  const updateContractKey = () => {
    setContractKey(new Date().getTime());
  };

  useEffect(() => {
    formik.setFieldValue('contract', '');
    updateContractKey();
  }, [formik.values.organization]);

  useEffect(() => {
    formik.setFieldValue(
      'total',
      contractsData?.contracts?.find(c => c.id === formik.values.contract)
        ?.total || null
    );
  }, [formik.values.contract]);

  useEffect(() => {
    if (contractsData?.contracts) {
      setContractOptions(
        contractsData.contracts.map(c => ({
          value: c.id,
          label: `${c.packageName}`,
          url: c.signedUrl
        }))
      );
    } else if (
      (contractsData as any)?.state === 'ERROR' &&
      (contractsData as any).message?.extensions?.code === 'FORBIDDEN'
    ) {
      toast.error((contractsData as any)?.message.message);
    }
  }, [contractsData]);

  return (
    <>
      <SEO title="Purchase Orders Submit" />
      <Card className="mt-4">
        <FormWrapper
          formik={formik}
          isLoading={state === FETCH_STATES.PROCESSING}
          onCancel={() => navigate('/members/purchase-orders')}
        >
          <div>
            <FormField<IForm>
              name="organization"
              type="select"
              className="w-1/2"
              options={organizationOptions}
              isLoading={isLoadingOrganization}
            />
            <div className="mt-1 sans-serif">
              <span
                onClick={() => navigate('/members/profile')}
                className="text-primary cursor-pointer sans-serif"
              >
                Click here{' '}
              </span>{' '}
              to add an organization affiliation
            </div>
          </div>

          {formik.values.organization && (
            <>
              <FormField<IForm>
                name="contract"
                type="select"
                key={'contract-' + contractKey}
                options={contractOptions}
                placeholder="Select Package"
                label="Package"
                isLoading={isLoadingContracts}
              />
              {formik.values.contract && (
                <a
                  className="text-primary cursor-pointer sans-serif w-max"
                  href={
                    contractOptions.find(
                      c => c.value === formik.values.contract
                    )?.url
                  }
                  target="_blank"
                >
                  View contract
                </a>
              )}
            </>
          )}

          {formik.values.contract && (
            <>
              <hr className="my-3" />
              <div>
                Please note that{' '}
                <span className="font-bold">
                  all purchases are non-refundable and non-transferable to other
                  seminars.
                </span>{' '}
                Please expect a confirmation email after payment has been
                processed.{' '}
                <span className="font-bold">Another separate email </span>
                will be sent in the following weeks with your ticket stub.
              </div>
              <div className="flex">
                <div className="w-2/3 mr-8">
                  <div className="flex mb-2">
                    <FormField<IForm>
                      name="contactName"
                      type="text"
                      className="mr-2 w-1/3"
                    />
                    <FormField<IForm>
                      name="contactEmail"
                      type="text"
                      className="mr-2 w-1/2"
                    />
                    <FormField<IForm>
                      name="contactPhone"
                      type="mask"
                      mask="phoneNumber"
                      className="w-1/3"
                    />
                  </div>
                  <div className="flex  mb-2">
                    <FormField<IForm>
                      name="number"
                      label="Purchase order number"
                      placeholder="Purchase order number"
                      type="text"
                      className="w-1/2 mr-2"
                    />
                    <FormField<IForm>
                      name="total"
                      label="Purchase order total"
                      placeholder="Purchase order total"
                      type="number"
                      className="w-1/2"
                    />
                  </div>
                </div>
              </div>
              <FormField<IForm>
                name="file"
                type="file"
                subheading="Add a .pdf file"
                acceptedFileTypes={{
                  'application/*': ['.pdf']
                }}
                filenamePath="filename"
              />
            </>
          )}
        </FormWrapper>
      </Card>
    </>
  );
};

export default PurchaseOrdersPackage;
