import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { FormField, FormWrapper } from '../../common/Form';
import * as Yup from 'yup';

interface EventSectionsListProps {
  path: any;
  selectedSections: any[];
  setSelected: (values: { id: string; ranking: number }[]) => void;
}

const EventSectionsList = ({
  path,
  selectedSections = [],
  setSelected
}: EventSectionsListProps) => {
  const [rankingOptions, setRankingOptions] = useState<any[]>([]);
  const formik = useFormik({
    initialValues: path?.groupings?.reduce((ac: any, grouping: any) => {
      grouping?.sections.forEach((section: any) => {
        ac[`${grouping.id}_${section.id}`] = null;
      });
      return ac;
    }, {}),

    validationSchema: Yup.object().shape(
      path?.groupings?.reduce((ac: any, grouping: any) => {
        grouping?.sections.forEach((section: any) => {
          ac[`${grouping.id}_${section.id}`] = null;
        });
        return ac;
      }, {})
    ),
    onSubmit: () => {}
  });

  const parseFormKey = (key: string) => {
    const result = key.split('_');
    return {
      groupingId: result[0],
      sectionId: result[1]
    };
  };

  const getFormKeyBySection = (id: string) => {
    return (
      Object.keys(formik.values).find(key => {
        return parseFormKey(key).sectionId === id;
      }) || ''
    );
  };

  const updateFormBySectionId = (id: string, value: number | null) => {
    const key = getFormKeyBySection(id);
    formik.setFieldValue(key as string, value, true);
  };

  useEffect(() => {
    if (selectedSections?.length) {
      selectedSections.forEach(section => {
        updateFormBySectionId(section.id, section.ranking);
      });
    }
  }, [selectedSections]);

  useEffect(() => {
    if (path) {
      const { requireRankedSelection, rankedSelectionAmount } = path;
      if (requireRankedSelection && rankedSelectionAmount > 0) {
        setRankingOptions(
          Array.from(Array(rankedSelectionAmount).keys()).map(item => ({
            label: `${item + 1}`,
            value: item + 1
          }))
        );
      }
    }
  }, [path]);

  const handleChange = (
    data: { value: number; label: string },
    name: string
  ) => {
    const groupingId = parseFormKey(getFormKeyBySection(name)).groupingId;
    const duplicate = Object.keys(formik.values).find(key => {
      return (
        parseFormKey(key).groupingId === groupingId &&
        formik.values[key] === data.value
      );
    });
    if (duplicate) {
      formik.setFieldValue(duplicate, null, true);
    }
    const result = [
      {
        id: name,
        ranking: data.value
      },
      ...Object.keys(formik.values)
        .filter(
          key =>
            !!formik.values[key] &&
            (duplicate ? key !== duplicate : true) &&
            parseFormKey(key).sectionId !== name
        )
        .map(key => ({
          id: parseFormKey(key).sectionId,
          ranking: formik.values[key] as number
        }))
    ];
    setSelected(result);
  };

  const hasSections = path.groupings?.some((g: any) => g.sections?.length);

  return (
    <FormWrapper hideActionButtons={true} formik={formik}>
      <fieldset className="">
        <legend className="sr-only">Sections</legend>
        <div>
          {!hasSections ? (
            <div className="py-4">
              <p className="text-gray-600 text-xs sans-serif">
                No sections in this group
              </p>
            </div>
          ) : (
            <>
              {path.groupings.map((grouping: any) => (
                <div
                  className="divide-y divide-gray-200 mb-4 p-4 border rounded-md border-gray-200"
                  key={grouping.id}
                >
                  <div className="text-gray-500 font-bold mb-1">
                    {grouping.title}
                  </div>
                  {grouping.sections.map((s: any) => (
                    <div className="relative flex items-start py-4" key={s.id}>
                      <div className="flex">
                        <div className="mr-4 w-28">
                          <FormField
                            name={`${grouping.id}_${s.id}`}
                            key={s.id + formik.values[`${grouping.id}_${s.id}`]}
                            type="select"
                            placeholder="Ranking"
                            label="Ranking"
                            onChange={data => handleChange(data, s.id)}
                            options={rankingOptions}
                          />
                        </div>
                        <div className="min-w-0 flex-1 text-sm">
                          <label
                            htmlFor={`${grouping.id}_${s.id}`}
                            className="font-bold text-gray-600 sans-serif cursor-pointer"
                          >
                            {s.displayTitle}
                          </label>
                          <p
                            id="comments-description"
                            className="text-gray-500 sans-serif mt-2 text-xs"
                            dangerouslySetInnerHTML={{ __html: s.description }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </>
          )}
        </div>
      </fieldset>
    </FormWrapper>
  );
};

export default EventSectionsList;
