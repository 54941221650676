import React from 'react';
import AcceptOrganizationInvitation from './AcceptOrganizationInvitation';

type AcceptOrganizationInvitationWithoutCodeProps = {
  path: string;
};
const AcceptOrganizationInvitationWithoutCode = (
  props: AcceptOrganizationInvitationWithoutCodeProps
) => {
  return <AcceptOrganizationInvitation />;
};

export default AcceptOrganizationInvitationWithoutCode;
