import React from 'react';
import SEO from '../../Seo';
import Card from '../../common/Card';
import { useQuery } from 'react-query';
import { getEventRegistrationRequests } from '../api';
import Button from '../../common/Button';
import { navigate } from 'gatsby';
import { format } from 'date-fns-tz';

const EventRegistrationRequests = () => {
  const { isFetching, error, data } = useQuery<
    { eventRegistrationRequests: any[] },
    Error
  >(['getEventRegistrationRequests'], async () => {
    const response = await getEventRegistrationRequests();
    if (response?.state !== 'SUCCESS') {
      throw new Error(response?.message || 'Something went wrong');
    }
    return response;
  });

  return (
    <>
      <SEO title="My Event Registrations" />
      <Card>
        <h3 className="text-primary font-bold text-2xl mb-2">
          My Event Registrations
        </h3>
        {isFetching && <p>Loading...</p>}
        {error && <p>Error: {error?.message}</p>}
        {data && (
          <>
            <ul>
              {data.eventRegistrationRequests.map(a => (
                <li
                  key={a.id}
                  className="mb-4 flex justify-between border-b border-gray-200 pb-4"
                >
                  <div>
                    <p className="font-bold text-gray-600 text-lg sans-serif mb-1">
                      {a.event.name}
                    </p>
                    <p className="text-gray-600 sans-serif">
                      Requested:{' '}
                      {format(new Date(a.createdAt), 'MM/dd/yyyy hh:mm')}
                    </p>
                    <p className="text-gray-600 sans-serif">
                      Seats: {a.seatsRequested}
                    </p>
                  </div>
                  <div>
                    <Button
                      text="View"
                      onClick={() => {
                        navigate(
                          `/members/events/registration/requests/${a.id}`
                        );
                      }}
                      size="sm"
                    />
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
      </Card>
    </>
  );
};

export default EventRegistrationRequests;
